import { Typography, useTheme } from "@mui/material";
import React from "react";
import { CSVReader } from "react-papaparse";
const CSVReaderComponent = ({
  title = "",
  body = "Drop CSV file here or click to upload",
  handleOnDrop = () => {},
  handleOnError = () => {},
  handleOnRemoveFile = () => {},
}) => {
  const theme = useTheme();
  return (
    <CSVReader
      config={{ header: true }}
      onDrop={handleOnDrop}
      onError={handleOnError}
      addRemoveButton
      onRemoveFile={handleOnRemoveFile}
      style={{
        dropArea: {
          borderColor: `${theme.palette.secondary.main}`,
          borderRadius: "4px",
          marginBottom: "10px",
        },
      }}
    >
      <Typography variant="h6" color="primary" align="center">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        {body}
      </Typography>
    </CSVReader>
  );
};

export default CSVReaderComponent;
