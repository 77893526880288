import React from "react";

const AuthContext = React.createContext();

AuthContext.displayName = "AuthContext";

const AuthProvider = ({ value, ...props }) => (
  <AuthContext.Provider value={value} {...props} />
);

const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
