import { gql, useMutation } from "@apollo/client";
import {
  Box,
  debounce,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../contexts/authContext";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import useQuantityToggle from "../../hooks/useQuantityToggle";
import formatMoney from "../../utils/formatMoney";
import QuantityToggle from "../common/QuantityToggle";
import AddToCartButton from "./AddToCartButton";

const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem(
    $data: CartItemUpdateInput!
    $where: CartItemWhereUniqueInput!
  ) {
    updateCartItem(data: $data, where: $where) {
      id
    }
  }
`;

const DELETE_CART_ITEM = gql`
  mutation DeleteCartItem($where: CartItemWhereUniqueInput!) {
    deleteCartItem(where: $where) {
      id
    }
  }
`;

const ProductActions = ({
  state,
  handleChange,
  unitOfMeasurements,
  sortedUnitOfMeasurements,
  discountPrice,
  originalPrice,
  initialQuantity,
  cartItemId,
  isCartPage = false,
}) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUserRefetch } = useAuthContext();
  const [updateCartItem, { loading: updateMutationLoading }] = useMutation(
    UPDATE_CART_ITEM,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message: "Update cart item error! " + error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: "Update cart item success!",
          severity: "success",
        });
        currentUserRefetch();
      },
    }
  );

  const [deleteCartItem, { loading: deleteMutationLoading }] = useMutation(
    DELETE_CART_ITEM,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message: "Delete cart item error! " + error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: "Delete cart item success!",
          severity: "success",
        });
        currentUserRefetch();
      },
    }
  );

  const { handleChangeQuantity, toggleAdd, toggleSubtract, quantity } =
    useQuantityToggle(initialQuantity);

  const handleNetworkAdd = async () => {
    toggleAdd();
    await updateCartItem({
      variables: {
        where: {
          id: cartItemId,
        },
        data: {
          quantity: {
            increment: 1,
          },
        },
      },
    });
  };
  const handleNetworkSubtract = async () => {
    toggleSubtract();
    if (quantity === 1) {
      await deleteCartItem({
        variables: {
          where: {
            id: cartItemId,
          },
        },
      });
    } else {
      await updateCartItem({
        variables: {
          where: {
            id: cartItemId,
          },
          data: {
            quantity: {
              decrement: 1,
            },
          },
        },
      });
    }
  };

  const handleNetworkChangeQuantity = async (e) => {
    const quantity = +e.target.value;
    handleChangeQuantity(e);
    if (quantity === 0) {
      await deleteCartItem({
        variables: {
          where: {
            id: cartItemId,
          },
        },
      });
    } else {
      debounce(async () => {
        await updateCartItem({
          variables: {
            where: {
              id: cartItemId,
            },
            data: {
              quantity: {
                set: quantity,
              },
            },
          },
        });
      }, 350)();
    }
  };

  const handleNetworkChangeUnit = async (e) => {
    handleChange(unitOfMeasurements)(e);
    const { id } = unitOfMeasurements.find(({ id }) => id === +e.target.value);
    await updateCartItem({
      variables: {
        where: {
          id: cartItemId,
        },
        data: {
          unitOfMeasurement: {
            connect: {
              id,
            },
          },
        },
      },
    });
  };
  return (
    <Box
      sx={{
        display: "grid",
        mt: "auto",
        width: "100%",
        gridTemplateColumns: {
          xs: "1fr",
          md: "repeat(2, 1fr)",
          lg: "2fr 1fr 2fr",
        },
        gridGap: (theme) => theme.spacing(1),
        alignItems: "center",
      }}
    >
      <QuantityToggle
        handleChangeQuantity={
          isCartPage ? handleNetworkChangeQuantity : handleChangeQuantity
        }
        toggleAdd={isCartPage ? handleNetworkAdd : toggleAdd}
        toggleSubtract={isCartPage ? handleNetworkSubtract : toggleSubtract}
        quantity={quantity}
        disabled={updateMutationLoading || deleteMutationLoading}
      />
      <FormControl variant="outlined" size="small">
        <Select
          labelId="unitOfMeasurement-label"
          id="unitOfMeasurement"
          name="unitOfMeasurement"
          value={state.unitOfMeasurement.id ?? ""}
          onChange={
            isCartPage
              ? handleNetworkChangeUnit
              : handleChange(unitOfMeasurements)
          }
          disabled={updateMutationLoading || deleteMutationLoading}
        >
          {sortedUnitOfMeasurements.map((unitOfMeasurement, index) => (
            <MenuItem key={index} value={unitOfMeasurement.id}>
              {unitOfMeasurement.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isCartPage ? (
        <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
          {formatMoney((discountPrice || originalPrice) * quantity)}
        </Typography>
      ) : (
        <AddToCartButton
          unitOfMeasurement={state.unitOfMeasurement}
          quantity={quantity}
        />
      )}
    </Box>
  );
};

export default ProductActions;
