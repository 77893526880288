import { useState } from "react";

const useDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(<></>);

  const handleDialogOpen = (component = <></>) => {
    setDialogComponent(component);
    setDialogOpen(true);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDialogOpen(false);
  };
  return { dialogOpen, dialogComponent, handleDialogOpen, handleDialogClose };
};

export default useDialog;
