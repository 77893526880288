import { useCallback, useReducer } from "react";

const actionTypes = {
  text: "text",
  number: "number",
  checkbox: "checkbox",
  file: "file",
  setState: "setState",
  object: "object",
};

const inputReducer = (state, { name, value, type, checked, files = [] }) => {
  switch (type) {
    case actionTypes.text || actionTypes.object: {
      return { ...state, [name]: value };
    }
    case actionTypes.number: {
      if (Object.is(NaN, parseFloat(value))) {
        return { ...state, [name]: value };
      } else {
        return { ...state, [name]: parseFloat(value) };
      }
    }
    case actionTypes.checkbox: {
      return { ...state, [name]: checked };
    }
    case actionTypes.file: {
      return { ...state, [name]: files[0] };
    }
    case actionTypes.setState: {
      return value;
    }
    default:
      return { ...state, [name]: value };
  }
};

const useInputChange = ({ reducer = inputReducer } = {}, initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setState = useCallback(
    (state) => {
      dispatch({
        type: actionTypes.setState,
        value: state,
      });
    },
    [dispatch]
  );
  const handleChange =
    (array, filterCriteria = "id") =>
    (e) => {
      if (array) {
        const value = array.find(
          (arrayItem) => arrayItem[filterCriteria] === e.target.value
        );
        dispatch({
          type: actionTypes.object,
          value,
          name: e.target.name,
        });
      } else {
        dispatch({
          name: e.target.name,
          value: e.target.value,
          type: e.target.type,
          checked: e.target.checked,
          files: e.target.files,
        });
      }
    };
  return { state, dispatch, setState, handleChange };
};

export { useInputChange, actionTypes, inputReducer };
