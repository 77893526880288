import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

const PROMOS = gql`
  query Promos(
    $skip: Int
    $take: Int
    $where: PromoWhereInput
    $orderBy: [PromoOrderByInput!]
  ) {
    promos(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      unitOfMeasurement {
        id
        name
        product {
          itemCode
          itemDescription
        }
      }
      buyQuantity
      freeQuantity
    }
  }
`;

const PROMOS_COUNT = gql`
  query PromosCount($where: PromoWhereInput) {
    promosCount(where: $where)
  }
`;

const DELETE_PROMO = gql`
  mutation DeletePromo($where: PromoWhereUniqueInput!) {
    deletePromo(where: $where) {
      id
    }
  }
`;

const DELETE_ALL_PROMOS = gql`
  mutation DeleteAllPromos {
    deleteAllPromos
  }
`;
const PromosPage = () => {
  const title = `Promos`;
  const client = useApolloClient();
  const history = useHistory();
  const { handleSnackbarOpen } = useSnackbarContext();
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    OR: [
      {
        unitOfMeasurement: {
          product: {
            itemCode: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
      },
      {
        unitOfMeasurement: {
          product: {
            itemDescription: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
      },
    ],
  };
  const { data: { promos } = {}, loading } = useQuery(PROMOS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: {
        unitOfMeasurementId: "asc",
      },
    },
  });

  const [deletePromo] = useMutation(DELETE_PROMO, {
    refetchQueries: [{ query: PROMOS_COUNT }],
    update(cache, { data: { deletePromo } }) {
      cache.modify({
        fields: {
          promos(existingPromos, { readField }) {
            return existingPromos.filter(
              (promo) => deletePromo.id !== readField("id", promo)
            );
          },
        },
      });
    },
  });
  const [deleteAllPromos] = useMutation(DELETE_ALL_PROMOS, {
    update(cache, { data: { deleteAllPromos } }) {
      cache.modify({
        fields: {
          promos(existingPromos) {
            return [];
          },
          promosCount(existingPromosCount) {
            return 0;
          },
        },
      });
    },
    onError: (error) =>
      handleSnackbarOpen({
        message: `Delete all ${title.toLocaleLowerCase()} error! ` + error,
        severity: "error",
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: `Delete all ${title.toLocaleLowerCase()} success!`,
        severity: "success",
      });
    },
  });
  const handleDelete = async (id) => {
    await deletePromo({
      variables: { where: { id } },
    });
  };
  const handleDeleteAll = async () => {
    await deleteAllPromos();
  };
  const handleDownload = async () => {
    const { data: { promos } = {} } = await client.query({
      query: PROMOS,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    setExportData(promos);
    csvLink.current.link.click();
  };
  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },

    { header: "Item Code", accessor: "unitOfMeasurement.product.itemCode" },
    {
      header: "Item Description",
      accessor: "unitOfMeasurement.product.itemDescription",
    },
    {
      header: "Unit of Measurement",
      accessor: "unitOfMeasurement.name",
    },
    {
      header: "Buy Quantity",
      accessor: "buyQuantity",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Free Quantity",
      accessor: "freeQuantity",
      Cell: (children) => children && children.toLocaleString(),
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Stack direction="row">
          <Button
            onClick={() =>
              handleDialogOpen(
                <AlertDialog
                  handleClose={handleDialogClose}
                  callback={handleDeleteAll}
                  dialogContentText="Are you sure you want to delete all items?"
                />
              )
            }
            variant="contained"
            color="secondary"
          >
            Delete All
          </Button>
          <ButtonGroup
            variant="contained"
            color="secondary"
            className="actionButtons"
          >
            <Button
              onClick={() =>
                history.push(`${history.location.pathname}/upload`)
              }
            >
              Upload
            </Button>
            <Button onClick={handleDownload}>Download</Button>
          </ButtonGroup>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={promos} columns={columns} />
        </Paper>
        <Pagination
          query={PROMOS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default PromosPage;
