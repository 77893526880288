import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

const UNIT_OF_MEASUREMENTS = gql`
  query UnitOfMeasurements(
    $skip: Int
    $take: Int
    $where: UnitOfMeasurementWhereInput
    $orderBy: [UnitOfMeasurementOrderByInput!]
  ) {
    unitOfMeasurements(
      skip: $skip
      take: $take
      where: $where
      orderBy: $orderBy
    ) {
      id
      multiplierToBasicUnit
      name
      product {
        itemCode
        itemDescription
      }
    }
  }
`;

const UNIT_OF_MEASUREMENTS_COUNT = gql`
  query UnitOfMeasurementsCount($where: UnitOfMeasurementWhereInput) {
    unitOfMeasurementsCount(where: $where)
  }
`;

const DELETE_UNIT_OF_MEASUREMENT = gql`
  mutation DeleteUnitOfMeasurement($where: UnitOfMeasurementWhereUniqueInput!) {
    deleteUnitOfMeasurement(where: $where) {
      id
    }
  }
`;

const DELETE_ALL_UNIT_OF_MEASUREMENTS = gql`
  mutation DeleteAllUnitOfMeasurements {
    deleteAllUnitOfMeasurements {
      id
    }
  }
`;
const UnitOfMeasurementsPage = () => {
  const title = `Unit of Measurements`;
  const history = useHistory();
  const client = useApolloClient();
  const csvLink = useRef(null);
  const { handleSnackbarOpen } = useSnackbarContext();
  const [exportData, setExportData] = useState([]);
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    OR: [
      {
        product: {
          itemCode: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      },
      {
        product: {
          itemDescription: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      },
    ],
  };
  const { data: { unitOfMeasurements } = {}, loading } = useQuery(
    UNIT_OF_MEASUREMENTS,
    {
      fetchPolicy: "network-only",
      variables: {
        skip: page * perPage - perPage,
        take: perPage,
        where,
        orderBy: {
          productId: "asc",
        },
      },
    }
  );

  const [deleteUnitOfMeasurement] = useMutation(DELETE_UNIT_OF_MEASUREMENT, {
    refetchQueries: [{ query: UNIT_OF_MEASUREMENTS_COUNT }],
    update(cache, { data: { deleteUnitOfMeasurement } }) {
      cache.modify({
        fields: {
          unitOfMeasurements(existingUnitOfMeasurements, { readField }) {
            return existingUnitOfMeasurements.filter(
              (unitOfMeasurement) =>
                deleteUnitOfMeasurement.id !==
                readField("id", unitOfMeasurement)
            );
          },
        },
      });
    },
  });
  const [deleteAllUnitOfMeasurements] = useMutation(
    DELETE_ALL_UNIT_OF_MEASUREMENTS,
    {
      refetchQueries: [{ query: UNIT_OF_MEASUREMENTS_COUNT }],
      onError: (error) =>
        handleSnackbarOpen({
          message: `Delete all ${title.toLocaleLowerCase()} error! ` + error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: `Delete all ${title.toLocaleLowerCase()} success!`,
          severity: "success",
        });
      },
      update(cache, { data: { deleteAllUnitOfMeasurements } }) {
        cache.modify({
          fields: {
            unitOfMeasurements(existingUnitOfMeasurements, { readField }) {
              return existingUnitOfMeasurements.filter(
                (unitOfMeasurement) =>
                  !deleteAllUnitOfMeasurements.some(
                    ({ id }) => id === readField("id", unitOfMeasurement)
                  )
              );
            },
          },
        });
      },
    }
  );
  const handleDelete = async (id) => {
    await deleteUnitOfMeasurement({
      variables: { where: { id } },
    });
  };
  const handleDeleteAll = async () => {
    await deleteAllUnitOfMeasurements();
  };
  const handleDownload = async () => {
    const { data: { unitOfMeasurements } = {} } = await client.query({
      query: UNIT_OF_MEASUREMENTS,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    setExportData(unitOfMeasurements);
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },

    { header: "Item Code", accessor: "product.itemCode" },
    { header: "Item Description", accessor: "product.itemDescription" },
    {
      header: "Multiplier to Basic Unit",
      accessor: "multiplierToBasicUnit",
      Cell: (children) => children && children.toLocaleString(),
    },
    { header: "Name", accessor: "name" },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Stack direction="row">
          <Button
            onClick={() =>
              handleDialogOpen(
                <AlertDialog
                  handleClose={handleDialogClose}
                  callback={handleDeleteAll}
                  dialogContentText="Are you sure you want to delete all items?"
                />
              )
            }
            variant="contained"
            color="secondary"
          >
            Delete All
          </Button>
          <ButtonGroup
            variant="contained"
            color="secondary"
            className="actionButtons"
          >
            <Button
              onClick={() =>
                history.push(`${history.location.pathname}/upload`)
              }
            >
              Upload
            </Button>
            <Button onClick={handleDownload}>Download</Button>
          </ButtonGroup>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={unitOfMeasurements} columns={columns} />
        </Paper>
        <Pagination
          query={UNIT_OF_MEASUREMENTS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default UnitOfMeasurementsPage;
