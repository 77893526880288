import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import { useInputChange } from "../../../hooks/useInputChange";
import Meta from "../../common/Meta";
import StaffForm from "./StaffForm";

const CREATE_STAFF = gql`
  mutation CreateStaff($data: StaffCreateInput!) {
    createStaff(data: $data) {
      id
    }
  }
`;
const CreateStaffPage = () => {
  const title = `Create Staff`;
  const history = useHistory();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { state, handleChange } = useInputChange();
  const [createStaff, { loading: mutationLoading }] = useMutation(
    CREATE_STAFF,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.push("/admin/staffs");
      },
    }
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, role } = state;
    await createStaff({
      variables: {
        data: {
          user: {
            create: {
              email,
              password,
            },
          },
          role,
        },
      },
    });
  };
  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <StaffForm
        title={title}
        state={state}
        handleChange={handleChange()}
        handleSubmit={handleSubmit}
        mutationLoading={mutationLoading}
      />
    </>
  );
};

export default CreateStaffPage;
