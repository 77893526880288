import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import { useInputChange } from "../../../hooks/useInputChange";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import CustomerForm from "./CustomerForm";

const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $data: CustomerUpdateInput!
    $where: CustomerWhereUniqueInput!
  ) {
    updateCustomer(data: $data, where: $where) {
      id
    }
  }
`;

const CUSTOMER = gql`
  query Customer($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      name
      address
      contactNumber
      status
      validIdPhoto
      affidavit
      user {
        id
        email
      }
      stores {
        accountCode
        name
      }
    }
  }
`;
const UpdateCustomerPage = () => {
  const title = `Update Customer`;
  let { id } = useParams();
  id = parseInt(id);
  const history = useHistory();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { state, handleChange, setState } = useInputChange();
  const { state: storesState, setState: storesSetState } = useInputChange({}, [
    { accountCode: null },
  ]);
  const { data: { customer } = {}, loading } = useQuery(CUSTOMER, {
    fetchPolicy: "network-only",
    variables: {
      where: { id },
    },
  });
  const [updateCustomer, { loading: mutationLoading }] = useMutation(
    UPDATE_CUSTOMER,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.goBack();
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id, __typename, status, validIdPhoto, affidavit, ...data } = state;

    const affidavitData = typeof affidavit !== "string" && { affidavit };

    const validIdPhotoData = typeof validIdPhoto !== "string" && {
      validIdPhoto,
    };
    await updateCustomer({
      variables: {
        data: {
          ...data,
          ...affidavitData,
          ...validIdPhotoData,
          status: {
            set: status,
          },
          stores: storesState.map(({ accountCode }) => {
            return { accountCode };
          }),
        },
        where: {
          id,
        },
      },
    });
  };
  useEffect(() => {
    if (customer) {
      const { user: { email } = {}, stores, ...data } = customer;
      storesSetState(stores);
      setState({ ...data, email, stores });
    }
  }, [customer, setState, storesSetState]);

  if (loading) return <Loading />;

  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <CustomerForm
        title={title}
        state={state}
        storesState={storesState}
        storesSetState={storesSetState}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        mutationLoading={mutationLoading}
      />
    </>
  );
};

export default UpdateCustomerPage;
