import { Box, Stack, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { useAuthContext } from "../../contexts/authContext";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import formatMoney from "../../utils/formatMoney";

const ProductDescription = ({
  state,
  originalPrice,
  discountPrice,
  itemDescription,
  itemCode,
  company,
  businessUnit,
  marketingDivision,
  brand,
  discount,
  isProductDetail = false,
}) => {
  const { isCustomer } = useAuthContext();
  const withDiscount = Boolean(discount);
  const withPromo = Boolean(state.unitOfMeasurement.promo);
  return (
    <>
      <Stack direction="row" alignItems="center">
        {(withDiscount || withPromo) && (
          <LocalOfferIcon
            sx={{
              color: yellow[700],
              mr: 1,
              stroke: (theme) => theme.palette.common.black,
            }}
          />
        )}
        <Typography
          variant={isProductDetail ? "h3" : "h5"}
          sx={{ fontWeight: "bold" }}
        >
          {itemDescription}
        </Typography>
      </Stack>
      {isProductDetail ? (
        <Stack sx={{ my: 2 }} spacing={2}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {itemCode}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {company}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {businessUnit}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {marketingDivision}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {brand}
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body1" color="textSecondary">
          {itemCode} | {company} | {businessUnit} | {marketingDivision} |{" "}
          {brand}
        </Typography>
      )}
      {isCustomer && (
        <Typography
          variant={isProductDetail ? "h4" : "h6"}
          gutterBottom={isProductDetail}
        >
          <Box
            component="span"
            sx={{ textDecoration: discount ? "line-through" : "none", mr: 1 }}
          >
            {formatMoney(originalPrice)}/{state.unitOfMeasurement.name}{" "}
            {withPromo &&
              `(${state.unitOfMeasurement.promo.buyQuantity} + ${state.unitOfMeasurement.promo.freeQuantity})`}
          </Box>
          {Boolean(discount) && (
            <Box component="span">
              {formatMoney(discountPrice)}/{state.unitOfMeasurement.name}{" "}
              {withPromo &&
                `(${state.unitOfMeasurement.promo.buyQuantity} + ${state.unitOfMeasurement.promo.freeQuantity})`}
            </Box>
          )}
        </Typography>
      )}
    </>
  );
};

export default ProductDescription;
