export const computeGrossTotal = (items) =>
  items.reduce((acc, cur) => {
    return (
      acc +
      cur?.quantity *
        cur?.unitOfMeasurement?.product?.listPrice *
        cur?.unitOfMeasurement?.multiplierToBasicUnit
    );
  }, 0);

export const computeProductDiscount = (items) =>
  items.reduce((acc, cur) => {
    return (
      acc +
      cur?.quantity *
        cur?.unitOfMeasurement?.product?.listPrice *
        cur?.unitOfMeasurement?.multiplierToBasicUnit *
        (cur?.unitOfMeasurement?.product?.discount
          ? cur?.unitOfMeasurement?.product?.discount
          : 0)
    );
  }, 0);

export const computeCustomerDiscount = (items, store) => {
  return items.reduce((acc, cur) => {
    return (
      acc +
      cur?.quantity *
        cur?.unitOfMeasurement?.product?.listPrice *
        cur?.unitOfMeasurement?.multiplierToBasicUnit *
        (cur?.unitOfMeasurement?.product?.discount
          ? 1 - cur?.unitOfMeasurement?.product?.discount
          : 1) *
        (store ? store.tradeDiscount : 0)
    );
  }, 0);
};

export const computeVat = (items, store) =>
  items.reduce((acc, cur) => {
    return (
      acc +
      cur?.quantity *
        cur?.unitOfMeasurement?.product?.listPrice *
        cur?.unitOfMeasurement?.multiplierToBasicUnit *
        (cur?.unitOfMeasurement?.product?.discount
          ? 1 - cur?.unitOfMeasurement?.product?.discount
          : 1) *
        (store ? 1 - store.tradeDiscount : 1) *
        (cur?.unitOfMeasurement?.product?.taxSchedule === "VAT Exempt"
          ? 0
          : 0.12)
    );
  }, 0);

export const computeNetTotal = (
  grossTotal,
  productDiscount,
  customerDiscount,
  vat
) => grossTotal - productDiscount - customerDiscount + vat;
