import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useAuthContext } from "../../contexts/authContext";
import ProductActions from "./ProductActions";
import ProductDescription from "./ProductDescription";
import {
  computeDiscountPrice,
  computeOriginalPrice,
  sortUnitOfMeasurements,
  useProductInputChange,
} from "./utils";

const ProductDetail = ({
  itemDescription,
  itemCode,
  company,
  businessUnit,
  marketingDivision,
  brand,
  listPrice,
  unitOfMeasurements,
  discount,
  taxSchedule,
  image,
  quantity: initialQuantity,
  cartItemId,
  isCartPage = false,
}) => {
  const { isCustomer } = useAuthContext();
  const sortedUnitOfMeasurements = sortUnitOfMeasurements(unitOfMeasurements);

  const { state, handleChange } = useProductInputChange(
    null,
    sortedUnitOfMeasurements
  );

  const originalPrice = computeOriginalPrice(
    listPrice,
    state.unitOfMeasurement.multiplierToBasicUnit,
    taxSchedule
  );

  const discountPrice = computeDiscountPrice(
    listPrice,
    state.unitOfMeasurement.multiplierToBasicUnit,
    taxSchedule,
    discount
  );

  return (
    <Paper sx={{ p: 2, border: 0 }} variant="outlined" square>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          {image ? (
            <img src={image} alt={itemDescription} />
          ) : (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.grey[400],
                minHeight: "300px",
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <ProductDescription
            state={state}
            originalPrice={originalPrice}
            discountPrice={discountPrice}
            itemDescription={itemDescription}
            itemCode={itemCode}
            company={company}
            businessUnit={businessUnit}
            marketingDivision={marketingDivision}
            brand={brand}
            discount={discount}
            isProductDetail={true}
          />
          {isCustomer && (
            <ProductActions
              state={state}
              handleChange={handleChange}
              unitOfMeasurements={unitOfMeasurements}
              sortedUnitOfMeasurements={sortedUnitOfMeasurements}
              discountPrice={discountPrice}
              originalPrice={originalPrice}
              initialQuantity={initialQuantity}
              cartItemId={cartItemId}
              isCartPage={isCartPage}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductDetail;
