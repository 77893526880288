import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import { useInputChange } from "../../../hooks/useInputChange";
import AdPlacementForm from "./AdPlacementForm";

const CREATE_AD_PLACEMENT = gql`
  mutation CreateAdPlacement($data: AdPlacementCreateInput!) {
    createAdPlacement(data: $data) {
      id
    }
  }
`;
const CreateAdPlacementPage = () => {
  const title = `Create Ad Placement`;
  const history = useHistory();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { state, handleChange } = useInputChange();
  const [createAdPlacement, { loading: mutationLoading }] = useMutation(
    CREATE_AD_PLACEMENT,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.push("/admin/ad-placements");
      },
    }
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    await createAdPlacement({
      variables: {
        data: state,
      },
    });
  };
  return (
    <AdPlacementForm
      title={title}
      state={state}
      handleChange={handleChange()}
      handleSubmit={handleSubmit}
      mutationLoading={mutationLoading}
    />
  );
};

export default CreateAdPlacementPage;
