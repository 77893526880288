import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/system";
import { endOfDay, format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../contexts/authContext";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import { useInputChange } from "../../hooks/useInputChange";
import {
  computeCustomerDiscount,
  computeGrossTotal,
  computeNetTotal,
  computeProductDiscount,
  computeVat,
} from "../../utils/compute";
import formatMoney from "../../utils/formatMoney";
import GrossSalesPerDivisionDialog from "../common/GrossSalesPerDivisionDialog";

const STORES = gql`
  query Stores($where: StoreWhereInput) {
    stores(where: $where) {
      accountCode
      name
      tradeDiscount
      deliveryArea {
        id
      }
    }
  }
`;

const DELIVERY_SCHEDULES = gql`
  query DeliverySchedules(
    $where: DeliveryScheduleWhereInput
    $orderBy: [DeliveryScheduleOrderByInput!]
  ) {
    deliverySchedules(where: $where, orderBy: $orderBy) {
      id
      date
    }
  }
`;

const CREATE_ORDER = gql`
  mutation CreateOrder($data: OrderCreateInput!) {
    createOrder(data: $data) {
      orderNumber
    }
  }
`;

const OrderSummary = ({
  currentUser = {},
  cartItems = [],
  store = {},
  deliverySchedule = {},
  isOrderSummary = false,
}) => {
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { currentUserRefetch } = useAuthContext();
  const [deliveryScheduleOptions, setDeliveryScheduleOptions] = useState([]);
  const { state, setState, handleChange } = useInputChange();
  const { handleSnackbarOpen } = useSnackbarContext();

  const grossSalesData = cartItems.map((item) => {
    return {
      quantity: item?.quantity,
      multiplierToBasicUnit: item?.unitOfMeasurement?.multiplierToBasicUnit,
      listPrice: item?.unitOfMeasurement?.product?.listPrice,
      marketingDivision: item?.unitOfMeasurement?.product?.marketingDivision,
    };
  });
  const [createOrder, { loading: mutationLoading }] = useMutation(
    CREATE_ORDER,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message: "Create order error! " + error,
          severity: "error",
        }),
      onCompleted: ({ createOrder }) => {
        handleSnackbarOpen({
          message: "Create order success!",
          severity: "success",
        });
        currentUserRefetch();
        history.push(`/order-confirmation/${createOrder.orderNumber}`);
      },
    }
  );
  const { data: { stores } = {} } = useQuery(STORES, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        customers: {
          some: {
            id: {
              equals: currentUser.customer.id,
            },
          },
        },
      },
    },
  });

  const { refetch } = useQuery(DELIVERY_SCHEDULES, {
    fetchPolicy: "network-only",
    skip: true,
  });

  const handleDialogClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (netTotal < 3000) {
      handleSnackbarOpen({
        message: "Create order error! Net total must be atleast ₱3000",
        severity: "error",
      });
    } else {
      createOrder({
        variables: {
          data: {
            orderNumber: "placeholder",
            grossTotal,
            productDiscount,
            customerDiscount,
            vat,
            deliveryScheduleDate: new Date(deliverySchedule.date),
            customerName: currentUser.customer.name,
            customerEmail: currentUser.email,
            storeName: store.name,
            storeAccountCode: store.accountCode,
            status: "FOR_APPROVAL",
            orderItems: {
              create: cartItems.map((item) => {
                return {
                  itemCode: item?.unitOfMeasurement?.product?.itemCode,
                  itemDescription:
                    item?.unitOfMeasurement?.product?.itemDescription,
                  marketingDivision:
                    item?.unitOfMeasurement?.product?.marketingDivision,
                  listPrice: item?.unitOfMeasurement?.product?.listPrice,
                  quantity: item?.quantity,
                  unitOfMeasurementName: item?.unitOfMeasurement?.name,
                  unitOfMeasurementMultiplierToBasicUnit:
                    item?.unitOfMeasurement.multiplierToBasicUnit,
                  promoFreeQuantity: item?.unitOfMeasurement?.promo
                    ?.freeQuantity
                    ? Math.ceil(
                        item?.quantity /
                          item?.unitOfMeasurement?.promo?.buyQuantity
                      ) * item?.unitOfMeasurement?.promo?.freeQuantity
                    : null,
                  taxSchedule: item?.unitOfMeasurement?.product?.taxSchedule,
                  discount: item?.unitOfMeasurement?.product?.discount,
                };
              }),
            },
          },
        },
      });
    }
  };

  const grossTotal =
    useMemo(() => {
      return computeGrossTotal(cartItems);
    }, [cartItems]) || 0;

  const productDiscount =
    useMemo(() => {
      return computeProductDiscount(cartItems);
    }, [cartItems]) || 0;

  const customerDiscount =
    useMemo(() => {
      return computeCustomerDiscount(cartItems, state.store);
    }, [cartItems, state.store]) || 0;

  const vat =
    useMemo(() => {
      return computeVat(cartItems, state.store);
    }, [cartItems, state.store]) || 0;

  const netTotal =
    useMemo(() => {
      return computeNetTotal(
        grossTotal,
        productDiscount,
        customerDiscount,
        vat
      );
    }, [grossTotal, productDiscount, customerDiscount, vat]) || 0;

  useEffect(() => {
    if (Object.keys(store).length > 0) {
      setState({ store });
    }
  }, [store, setState]);

  if (!stores) return null;

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4" color="primary" gutterBottom>
          Order Summary
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          {isOrderSummary ? (
            <TextField
              value={store.name ?? ""}
              disabled={true}
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <FormControl
              variant="outlined"
              fullWidth
              required
              sx={{ mb: 2 }}
              size="small"
              disabled={isOrderSummary}
            >
              <InputLabel id="store-label">Store</InputLabel>
              <Select
                labelId="store-label"
                label="Store *"
                id="store"
                name="store"
                value={state.store?.accountCode ?? ""}
                onChange={async (e) => {
                  handleChange(stores, "accountCode")(e);
                  const store = stores.find(
                    ({ accountCode }) => accountCode === e.target.value
                  );

                  const { data: { deliverySchedules } = {} } = await refetch({
                    where: {
                      deliveryArea: {
                        id: {
                          equals: store?.deliveryArea?.id,
                        },
                      },
                      date: {
                        gt: endOfDay(new Date()),
                      },
                    },
                    orderBy: {
                      date: "asc",
                    },
                  });
                  setDeliveryScheduleOptions(
                    deliverySchedules.map((item) => {
                      return {
                        ...item,
                        date: format(new Date(item.date), "dd MMM yyyy"),
                      };
                    })
                  );
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {stores?.map(({ accountCode, name }) => (
                  <MenuItem key={accountCode} value={accountCode}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontWeight: "bold", my: 0.5 }}>
                Gross Total
              </Typography>
              <IconButton
                aria-label="Breakdown by marketing division"
                onClick={() => {
                  handleDialogOpen();
                }}
                size="large"
              >
                <ErrorIcon fontSize="small" />
              </IconButton>
            </Stack>
            <Typography>{formatMoney(grossTotal)}</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", my: 0.5 }}>
              Product Discount
            </Typography>
            <Typography>{formatMoney(-productDiscount)}</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", my: 0.5 }}>
              Customer Discount
            </Typography>
            <Typography>{formatMoney(-customerDiscount)}</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold", my: 0.5 }}>VAT</Typography>
            <Typography>{formatMoney(vat)}</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold" }}>Net Total:</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {formatMoney(netTotal)}
            </Typography>
          </Stack>
          {isOrderSummary ? (
            <TextField
              value={deliverySchedule.date ?? ""}
              disabled={true}
              fullWidth
              label="Delivery Schedule"
              size="small"
              sx={{ mt: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <FormControl
              variant="outlined"
              fullWidth
              required
              sx={{ mt: 2 }}
              size="small"
              disabled={!state.store}
            >
              <InputLabel id="deliverySchedule-label">
                Delivery schedule
              </InputLabel>
              <Select
                labelId="deliverySchedule-label"
                label="Delivery schedule *"
                id="deliverySchedule"
                name="deliverySchedule"
                value={state?.deliverySchedule?.id ?? ""}
                onChange={handleChange(deliveryScheduleOptions)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {deliveryScheduleOptions?.map(({ id, date }) => (
                  <MenuItem key={date} value={id}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Typography
            variant="subtitle2"
            color="error"
            sx={{ fontWeight: "bold", mt: 1 }}
          >
            Disclaimer:
          </Typography>
          <Typography variant="subtitle2" color="error" sx={{ mb: 1 }}>
            Inventory and delivery schedule are not guaranteed. Price is subject
            to change and order is subject to approval.
          </Typography>
          {isOrderSummary ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={mutationLoading}
            >
              Place Order
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (!state?.store?.name || !state?.deliverySchedule?.date) {
                  handleSnackbarOpen({
                    message:
                      "Proceed to checkout error! Please select both store and delivery schedule.",
                    severity: "error",
                  });
                } else {
                  history.push({
                    pathname: "/order-summary",
                    state: {
                      store: state.store,
                      deliverySchedule: state.deliverySchedule,
                    },
                  });
                }
              }}
            >
              Proceed to Checkout
            </Button>
          )}
        </Box>
      </Paper>
      <GrossSalesPerDivisionDialog
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        grossSalesData={grossSalesData}
      />
    </>
  );
};

export default OrderSummary;
