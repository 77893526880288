import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        storeProducts: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        storeProductsCount: {
          keyArgs: false,
        },
      },
    },
  },
});

const httpLink = new createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_URI}`,
  credentials: "include",
});

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     alert(
//       graphQLErrors.map(
//         ({ message, locations, path }) =>
//           `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) {
//     alert(`[Network error]: ${networkError}`);
//   }
// });

const apolloClient = new ApolloClient({
  cache,
  // link: from([errorLink, httpLink]),
  link: httpLink,
});

export default apolloClient;
