import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import usePopper from "../../hooks/usePopper";
import logo from "../../images/logos/logo112x100.png";
import Logo from "../../styles/components/Logo";
import Popper from "../common/Popper";
import SignoutButton from "../authentication/SignoutButton";
import MiniCart from "../common/MiniCart";

const NavBar = () => {
  const { currentUser, currentUserLoading } = useAuthContext();

  const { handleClick, setOpen, open, anchorEl, placement } = usePopper();
  const history = useHistory();
  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters={!upMd}>
        <IconButton
          aria-label="home"
          onClick={() => history.push("/")}
          sx={{ p: 0 }}
        >
          <Logo src={logo} alt="logo" />
        </IconButton>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            "& :not(:last-child)": { mr: 1 },
          }}
        >
          <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
            {upMd ? (
              <Button
                size="large"
                color="inherit"
                onClick={() => history.push("/products")}
              >
                Products
              </Button>
            ) : (
              <IconButton aria-label="products" color="inherit" size="large">
                <MedicalServicesIcon />
              </IconButton>
            )}
            {upMd ? (
              <Button
                size="large"
                color="inherit"
                onClick={() => history.push("/contact-us")}
              >
                Contact Us
              </Button>
            ) : (
              <IconButton aria-label="contact us" color="inherit" size="large">
                <ContactSupportIcon />
              </IconButton>
            )}
            {!currentUser?.customer?.id && (
              <Button
                size={upMd ? "large" : "small"}
                color="inherit"
                variant={upMd ? "outlined" : "text"}
                onClick={() => history.push("/signin")}
              >
                Sign In
              </Button>
            )}
            {currentUser?.customer?.id && (
              <>
                <MiniCart />
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <IconButton
                      aria-label="user avatar"
                      color="inherit"
                      onClick={handleClick("bottom-end")}
                      sx={{ p: 0 }}
                    >
                      <Avatar
                        alt={currentUser.email}
                        sx={{
                          bgcolor: (theme) => theme.palette.secondary.main,
                        }}
                      >
                        {currentUser.email.charAt(0).toUpperCase()}
                      </Avatar>
                    </IconButton>
                    <Popper
                      open={open}
                      anchorEl={anchorEl}
                      placement={placement}
                    >
                      <Stack alignItems="center" sx={{ mt: 2 }}>
                        <AccountCircleIcon fontSize="large" color="primary" />
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                        >
                          {currentUser.email}
                        </Typography>
                        <Link
                          component="button"
                          underline="none"
                          variant="body1"
                          onClick={() => history.push("/orders")}
                        >
                          Orders
                        </Link>
                        <SignoutButton
                          variant="contained"
                          callback={() => {
                            history.push("/");
                            setOpen(false);
                          }}
                        />
                      </Stack>
                    </Popper>
                  </div>
                </ClickAwayListener>
              </>
            )}
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
