import { gql, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";

const STAFFS = gql`
  query Staffs {
    staffs {
      id
      role
      user {
        id
        email
      }
    }
  }
`;

const DELETE_STAFF = gql`
  mutation DeleteStaff($where: StaffWhereUniqueInput!) {
    deleteStaff(where: $where) {
      id
    }
  }
`;
const StaffsPage = () => {
  const title = `Staffs`;
  const history = useHistory();
  const { handleDialogOpen, handleDialogClose } = useDialogContext();

  const { data: { staffs } = {}, loading } = useQuery(STAFFS, {
    fetchPolicy: "network-only",
  });

  const [deleteStaff] = useMutation(DELETE_STAFF, {
    update(cache, { data: { deleteStaff } }) {
      cache.modify({
        fields: {
          staffs(existingStaffs, { readField }) {
            return existingStaffs.filter(
              (staff) => deleteStaff.id !== readField("id", staff)
            );
          },
        },
      });
    },
  });

  const handleUpdate = (id) => {
    history.push(`${history.location.pathname}/${id}`);
  };
  const handleDelete = async (id) => {
    await deleteStaff({
      variables: { where: { id } },
    });
  };
  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Update">
              <IconButton
                aria-label="update"
                color="secondary"
                onClick={() => handleUpdate(children)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Email", accessor: "user.email" },
    { header: "Role", accessor: "role" },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push(`${history.location.pathname}/create`)}
          className="actionButton"
        >
          Create
        </Button>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Paper className="dataTableContainer">
          <DataTable data={staffs} columns={columns} />
        </Paper>
      </PageListView>
    </>
  );
};

export default StaffsPage;
