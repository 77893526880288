import { gql, useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { parse } from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import CSVReaderComponent from "../../common/CSVReaderComponent";
import FormContainer from "../../common/FormContainer";
import Meta from "../../common/Meta";

const UPLOAD_SALES_INVOICES = gql`
  mutation UploadSalesInvoices($data: [SalesInvoiceCreateInput]!) {
    uploadSalesInvoices(data: $data)
  }
`;

const UploadSalesInvoicesPage = () => {
  const title = `Upload Sales Invoices`;
  const history = useHistory();
  const [parsedData, setParsedData] = useState([]);

  const { handleSnackbarOpen } = useSnackbarContext();
  const [uploadSalesInvoices, { loading: mutationLoading }] = useMutation(
    UPLOAD_SALES_INVOICES,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        history.push("/admin/sales-invoices");
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
      },
    }
  );
  const handleOnDrop = (data) => {
    let initialData = data
      .filter(({ errors }) => errors.length === 0)
      .map(({ data }) => data);
    let cleanedData = initialData.filter(({ listPrice }) => +listPrice !== 0);

    const itemsWherePriceEqualsZero = initialData.filter(
      ({ listPrice }) => +listPrice === 0
    );

    for (let item of itemsWherePriceEqualsZero) {
      const indexInDataMatchingItem = cleanedData.findIndex(
        ({ itemCode, unitOfMeasurementName, orderNumber }) =>
          itemCode === item.itemCode &&
          unitOfMeasurementName === item.unitOfMeasurementName &&
          orderNumber === item.orderNumber
      );

      cleanedData[indexInDataMatchingItem].promoFreeQuantity = +item.quantity;
    }
    console.log(cleanedData);
    setParsedData(cleanedData);
  };
  const handleOnError = (err, file, inputElem, reason) => {
    handleSnackbarOpen({
      message: "CSV parse error! " + err,
      severity: "error",
    });
  };
  const handleOnRemoveFile = () => {
    setParsedData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await uploadSalesInvoices({
      variables: {
        data: parsedData.map(
          ({
            transactionNumber,
            transactionDate,
            storeAccountCode,
            storeName,
            expectedDeliveryDate,
            orderNumber,
            itemCode,
            itemDescription,
            quantity,
            unitOfMeasurementName,
            listPrice,
            gross,
            vat,
            subtotal,
            netBeforeTradeDiscount,
            promoFreeQuantity,
          }) => {
            return {
              transactionNumber,
              transactionDate: parse(transactionDate, "M/dd/yyyy", new Date()),
              storeAccountCode,
              storeName,
              expectedDeliveryDate: parse(
                expectedDeliveryDate,
                "M/dd/yyyy",
                new Date()
              ),
              order: {
                connect: {
                  orderNumber,
                },
              },
              salesInvoiceItems: {
                connectOrCreate: [
                  {
                    where: {
                      itemCode_unitOfMeasurementName_salesInvoiceId: {
                        itemCode,
                        unitOfMeasurementName,
                        salesInvoiceId: transactionNumber,
                      },
                    },
                    create: {
                      itemCode,
                      itemDescription,
                      quantity: parseInt(quantity.toString().replace(/,/g, "")),
                      unitOfMeasurementName,
                      listPrice: parseFloat(
                        listPrice.toString().replace(/,/g, "")
                      ),
                      gross: parseFloat(gross.toString().replace(/,/g, "")),
                      vat: parseFloat(vat.toString().replace(/,/g, "")),
                      netBeforeTradeDiscount: parseFloat(
                        netBeforeTradeDiscount.toString().replace(/,/g, ""),
                        ""
                      ),
                      subtotal: parseFloat(
                        subtotal.toString().replace(/,/g, "")
                      ),
                      promoFreeQuantity: promoFreeQuantity
                        ? parseInt(
                            promoFreeQuantity.toString().replace(/,/g, "")
                          )
                        : null,
                      orderItem: {
                        connect: {
                          itemCode_unitOfMeasurementName_orderId: {
                            itemCode,
                            unitOfMeasurementName,
                            orderId: orderNumber,
                          },
                        },
                      },
                    },
                  },
                ],
              },
            };
          }
        ),
      },
    });
  };

  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <FormContainer maxWidth="xs">
        <Typography
          component="h1"
          variant="h4"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <CSVReaderComponent
            handleOnDrop={handleOnDrop}
            handleOnError={handleOnError}
            handleOnRemoveFile={handleOnRemoveFile}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={parsedData.length === 0 || mutationLoading}
          >
            Submit
          </Button>
        </Box>
      </FormContainer>
    </>
  );
};

export default UploadSalesInvoicesPage;
