import { gql, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

const DELIVERY_AREAS = gql`
  query DeliveryAreas(
    $skip: Int
    $take: Int
    $where: DeliveryAreaWhereInput
    $orderBy: [DeliveryAreaOrderByInput!]
  ) {
    deliveryAreas(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      name
    }
  }
`;

const DELIVERY_AREAS_COUNT = gql`
  query DeliveryAreasCount($where: DeliveryAreaWhereInput) {
    deliveryAreasCount(where: $where)
  }
`;

const DELETE_DELIVERY_AREA = gql`
  mutation DeleteDeliveryArea($where: DeliveryAreaWhereUniqueInput!) {
    deleteDeliveryArea(where: $where) {
      id
    }
  }
`;
const DeliveryAreasPage = () => {
  const title = `Delivery Areas`;
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    name: {
      contains: searchQueryString,
      mode: "insensitive",
    },
  };
  const { data: { deliveryAreas } = {} } = useQuery(DELIVERY_AREAS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: {
        name: "asc",
      },
    },
  });

  const [deleteDeliveryArea] = useMutation(DELETE_DELIVERY_AREA, {
    refetchQueries: [{ query: DELIVERY_AREAS_COUNT }],
    update(cache, { data: { deleteDeliveryArea } }) {
      cache.modify({
        fields: {
          deliveryAreas(existingDeliveryAreas, { readField }) {
            return existingDeliveryAreas.filter(
              (deliveryArea) =>
                deleteDeliveryArea.id !== readField("id", deliveryArea)
            );
          },
        },
      });
    },
  });

  const handleDelete = async (id) => {
    await deleteDeliveryArea({
      variables: { where: { id } },
    });
  };
  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Name", accessor: "name" },
  ];
  if (!deliveryAreas) return <Loading />;
  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={deliveryAreas} columns={columns} />
        </Paper>
        <Pagination
          query={DELIVERY_AREAS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default DeliveryAreasPage;
