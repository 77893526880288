import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import formatMoney from "../../utils/formatMoney";

const GrossSalesPerDivisionDialog = ({
  dialogOpen,
  handleDialogClose,
  grossSalesData,
  computeGrossTotal = false,
}) => {
  let grossSalesPerDivision = [];
  for (let item of grossSalesData) {
    const itemIndex = grossSalesPerDivision.findIndex(
      ({ marketingDivision }) => item.marketingDivision === marketingDivision
    );
    if (itemIndex > -1) {
      grossSalesPerDivision[itemIndex].grossTotal += computeGrossTotal
        ? 0
        : item.listPrice * item.quantity * item.multiplierToBasicUnit;
    } else {
      grossSalesPerDivision.push({
        marketingDivision: item.marketingDivision,
        grossTotal: computeGrossTotal
          ? 0
          : item.listPrice * item.quantity * item.multiplierToBasicUnit,
      });
    }
  }
  const grossTotal = grossSalesPerDivision.reduce((acc, cur) => {
    if (!cur.grossTotal) return acc;
    return acc + cur.grossTotal;
  }, 0);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-describedby="dialog"
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "300px",
          },
        }}
      >
        <DialogTitle sx={{ color: (theme) => theme.palette.primary.main }}>
          Gross Sales Per Division
        </DialogTitle>
        <DialogContent>
          {grossSalesPerDivision.map((item) => (
            <Stack
              key={item.marketingDivision}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {item.marketingDivision}
              </Typography>
              <Typography>{formatMoney(item.grossTotal)}</Typography>
            </Stack>
          ))}
          <Divider sx={{ my: 0.5 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: "bold" }}>Gross Total:</Typography>
            <Typography>{formatMoney(grossTotal)}</Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GrossSalesPerDivisionDialog;
