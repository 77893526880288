import { Card, CardActionArea, CardActions } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../contexts/authContext";
import ProductActions from "./ProductActions";
import ProductDescription from "./ProductDescription";
import {
  computeDiscountPrice,
  computeOriginalPrice,
  sortUnitOfMeasurements,
  useProductInputChange,
} from "./utils";

const Product = ({
  itemDescription,
  itemCode,
  company,
  businessUnit,
  marketingDivision,
  brand,
  listPrice,
  unitOfMeasurements,
  discount,
  taxSchedule,
  quantity: initialQuantity,
  unitOfMeasurementId,
  cartItemId,
  isCartPage = false,
}) => {
  const history = useHistory();
  const { isCustomer } = useAuthContext();
  const sortedUnitOfMeasurements = sortUnitOfMeasurements(unitOfMeasurements);

  const { state, handleChange } = useProductInputChange(
    unitOfMeasurementId,
    sortedUnitOfMeasurements
  );

  const originalPrice = computeOriginalPrice(
    listPrice,
    state.unitOfMeasurement.multiplierToBasicUnit,
    taxSchedule
  );

  const discountPrice =
    discount &&
    computeDiscountPrice(
      listPrice,
      state.unitOfMeasurement.multiplierToBasicUnit,
      taxSchedule,
      discount
    );

  return (
    <Card variant="outlined">
      <CardActionArea
        sx={{ pt: 1, px: 2 }}
        onClick={() => history.push(`/products/${itemCode}`)}
      >
        <ProductDescription
          state={state}
          originalPrice={originalPrice}
          discountPrice={discountPrice}
          itemDescription={itemDescription}
          itemCode={itemCode}
          company={company}
          businessUnit={businessUnit}
          marketingDivision={marketingDivision}
          brand={brand}
          discount={discount}
        />
      </CardActionArea>
      {isCustomer && (
        <CardActions sx={{ pt: 0, pb: 1 }}>
          <ProductActions
            state={state}
            handleChange={handleChange}
            unitOfMeasurements={unitOfMeasurements}
            sortedUnitOfMeasurements={sortedUnitOfMeasurements}
            discountPrice={discountPrice}
            originalPrice={originalPrice}
            initialQuantity={initialQuantity}
            cartItemId={cartItemId}
            isCartPage={isCartPage}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default Product;
