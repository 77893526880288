import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { endOfDay, format, startOfDay } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import useDateFilter from "../../../hooks/useDateFilter";
import { useInputChange } from "../../../hooks/useInputChange";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import formatMoney from "../../../utils/formatMoney";
import iterateObjectValuesNullToBlank from "../../../utils/iterateObjectValuesNullToBlank";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";
import DateFilter from "../../common/DateFilter";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

const ORDERS = gql`
  query Orders(
    $skip: Int
    $take: Int
    $where: OrderWhereInput
    $orderBy: [OrderOrderByInput!]
  ) {
    orders(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      orderNumber
      customerName
      storeName
      storeAccountCode
      createdAt
      deliveryScheduleDate
      status
      typeOfApproval
      netTotal
      salesInvoice {
        transactionNumber
      }
      orderItems {
        id
        itemCode
        quantity
        unitOfMeasurementName
      }
    }
  }
`;

const ORDERS_COUNT = gql`
  query OrdersCount($where: OrderWhereInput) {
    ordersCount(where: $where)
  }
`;

const ORDER_STATUS = gql`
  query OrderStatus {
    __type(name: "OrderStatus") {
      enumValues {
        name
      }
    }
  }
`;

const DELETE_ORDER = gql`
  mutation DeleteOrder($where: OrderWhereUniqueInput!) {
    deleteOrder(where: $where) {
      orderNumber
    }
  }
`;

const CANCEL_ORDER = gql`
  mutation CancelOrder($orderNumber: String!) {
    cancelOrder(orderNumber: $orderNumber) {
      orderNumber
    }
  }
`;
const OrdersPage = () => {
  const title = `Orders`;
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const { handleSnackbarOpen } = useSnackbarContext();
  const client = useApolloClient();
  const history = useHistory();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();

  const {
    handleSubmitQueryString: handleSubmitFilter,
    initialQueryStringValue: filterQueryString,
  } = useQueryString("filter");
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();
  const { state, handleChange } = useInputChange(
    {},
    { status: filterQueryString }
  );
  const customerOrStoreWhere = searchQueryString && {
    OR: [
      {
        customerName: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        storeName: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
    ],
  };
  const statusWhere = filterQueryString && {
    status: {
      equals: filterQueryString,
    },
  };
  const dateWhere = startDateQueryString &&
    endDateQueryString && {
      createdAt: {
        gte: startOfDay(new Date(startDateQueryString)),
        lte: endOfDay(new Date(endDateQueryString)),
      },
    };
  const where = {
    ...customerOrStoreWhere,
    ...statusWhere,
    ...dateWhere,
  };
  const { data: { orders } = {}, loading: ordersLoading } = useQuery(ORDERS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: {
        createdAt: "desc",
      },
    },
  });

  const {
    data: {
      __type: { enumValues: orderStatus } = {},
      loading: orderStatusLoading,
    } = {},
  } = useQuery(ORDER_STATUS, {
    fetchPolicy: "network-only",
  });

  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    update(cache, { data: { cancelOrder } }) {
      cache.modify({
        fields: {
          orders(existingOrders, { readField }) {
            return existingOrders.map((order) => {
              if (cancelOrder.orderNumber !== readField("orderNumber", order)) {
                return order;
              } else {
                return { ...order, status: "CANCELLED" };
              }
            });
          },
        },
      });
    },
    onError: (error) =>
      handleSnackbarOpen({
        message: "Cancel order error! " + error,
        severity: "error",
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: "Cancel order success!",
        severity: "success",
      });
    },
  });
  const [deleteOrder] = useMutation(DELETE_ORDER, {
    refetchQueries: [{ query: ORDERS_COUNT }],
    update(cache, { data: { deleteOrder } }) {
      cache.modify({
        fields: {
          orders(existingOrders, { readField }) {
            return existingOrders.filter(
              (order) =>
                deleteOrder.orderNumber !== readField("orderNumber", order)
            );
          },
        },
      });
    },
    onError: (error) =>
      handleSnackbarOpen({
        message: "Delete order error! " + error,
        severity: "error",
      }),
  });

  const handleDownload = async () => {
    const { data: { orders } = {} } = await client.query({
      query: ORDERS,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    const transformedOrders = orders.reduce((acc, { orderItems, ...cur }) => {
      return [
        ...acc,
        ...orderItems.map((orderItem) => {
          return { ...orderItem, ...cur };
        }),
      ];
    }, []);
    setExportData(
      transformedOrders.map(
        ({
          storeName,
          storeAccountCode,
          createdAt,
          deliveryScheduleDate,
          ...item
        }) => {
          return {
            ...iterateObjectValuesNullToBlank(item),
            createdAt: format(new Date(createdAt), "MM/dd/yyyy"),
            deliveryScheduleDate: format(
              new Date(deliveryScheduleDate),
              "MM/dd/yyyy"
            ),
            customer: `${storeAccountCode} ${storeName}`,
            memo: "",
            location: "Bestline Main Warehouse",
          };
        }
      )
    );
    csvLink.current.link.click();
  };

  const handleCancelOrder = async (orderNumber) => {
    await cancelOrder({
      variables: {
        orderNumber,
      },
    });
  };

  const handleDelete = async (orderNumber) => {
    await deleteOrder({
      variables: { where: { orderNumber } },
    });
  };
  const columns = [
    {
      header: "Actions",
      accessor: "orderNumber",
      Cell: (children) => {
        if (children) {
          const order = orders.find(
            ({ orderNumber }) => orderNumber === children
          );
          return (
            <>
              <Tooltip title="Cancel">
                <span>
                  <IconButton
                    disabled={
                      order?.status === "CANCELLED" ||
                      order?.status === "APPROVED"
                    }
                    aria-label="cancel"
                    color="secondary"
                    onClick={() => {
                      handleDialogOpen(
                        <AlertDialog
                          handleClose={handleDialogClose}
                          callback={() => handleCancelOrder(children)}
                          dialogContentText="Are you sure you want to cancel this item?"
                        />
                      );
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="View">
                <IconButton
                  aria-label="view"
                  color="secondary"
                  onClick={() =>
                    history.push(`${history.location.pathname}/${children}`)
                  }
                  size="large"
                >
                  <PageviewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    disabled={order?.status === "APPROVED"}
                    aria-label="delete"
                    color="secondary"
                    onClick={() => {
                      handleDialogOpen(
                        <AlertDialog
                          handleClose={handleDialogClose}
                          callback={() => handleDelete(children)}
                          dialogContentText="Are you sure you want to delete this item?"
                        />
                      );
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          );
        } else return null;
      },
    },

    { header: "Order Number", accessor: "orderNumber" },
    {
      header: "Customer Name",
      accessor: "customerName",
    },
    {
      header: "Store Name",
      accessor: "storeName",
    },
    {
      header: "Request Date",
      accessor: "createdAt",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy h:mm:ss b"),
    },
    {
      header: "Delivery Schedule",
      accessor: "deliveryScheduleDate",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Status",
      accessor: "status",
      Cell: (children) => children && children.replace(/_/g, " "),
    },
    {
      header: "Type of Approval",
      accessor: "typeOfApproval",
    },
    {
      header: "Total",
      accessor: "netTotal",
      Cell: (children) => children && formatMoney(children),
    },
  ];

  const downloadColumns = [
    {
      header: "External ID",
      accessor: "orderNumber",
    },
    {
      header: "Customer",
      accessor: "customer",
    },
    {
      header: "PO # REF.",
      accessor: "deliveryScheduleDate",
    },
    {
      header: "Memo",
      accessor: "orderNumber",
    },
    {
      header: "Location",
      accessor: "location",
    },
    {
      header: "ITEM CODE",
      accessor: "itemCode",
    },
    {
      header: "QUANTITY",
      accessor: "quantity",
    },
    {
      header: "UNITS",
      accessor: "unitOfMeasurementName",
    },
    {
      header: "COVERAGE DATE",
      accessor: "createdAt",
    },
    {
      header: "ORDER CAPTURE",
      accessor: "createdAt",
    },
    {
      header: "PURCHASE ORDER SUBMISSION",
      accessor: "createdAt",
    },
  ];

  if (ordersLoading || orderStatusLoading) return <Loading />;

  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={downloadColumns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownload}
          className="actionButton"
        >
          Download
        </Button>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
          mb={1}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />
          <div>
            <FormControl variant="outlined" size="small">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                label="Store"
                id="status"
                name="status"
                value={state?.status ?? ""}
                onChange={(e) => {
                  handleChange()(e);
                  handleSubmitFilter("direct")(e);
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  minWidth: "320px",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {orderStatus?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name.replace(/_/g, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={orders} columns={columns} />
        </Paper>
        <Pagination
          query={ORDERS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default OrdersPage;
