import React from "react";

const SnackbarContext = React.createContext();

SnackbarContext.displayName = "SnackbarContext";

const SnackbarProvider = ({ value, ...props }) => (
  <SnackbarContext.Provider value={value} {...props} />
);

const useSnackbarContext = () => {
  return React.useContext(SnackbarContext);
};

export { SnackbarProvider, useSnackbarContext };
