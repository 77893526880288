import { gql, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useEffect } from "react";
import { useAutocomplete } from "../../../hooks/useAutocomplete";
import {
  handleNestedAdd,
  handleNestedSubtract,
} from "../../../utils/handleNestedInputChange";
import FormContainer from "../../common/FormContainer";
import Loading from "../../common/Loading";
import { STORES } from "../stores/StoresPage";

const CUSTOMER_STATUS = gql`
  query CustomerStatus {
    __type(name: "CustomerStatus") {
      enumValues {
        name
      }
    }
  }
`;

const CustomerForm = ({
  title = "",
  state = {},
  storesState = [],
  storesSetState = () => {},
  handleChange = () => {},
  handleSubmit = () => {},
  mutationLoading = false,
}) => {
  const {
    open: storeOpen,
    setOpen: storeSetOpen,
    options: storeOptions,
    setOptions: storeSetOptions,
    loading: storeLoading,
    dispatchInput: storeDispatchInput,
  } = useAutocomplete();

  const handleAddStore = () =>
    handleNestedAdd(storesState, storesSetState, [null]);

  const handleSubtractStore = (idx) =>
    handleNestedSubtract(storesState, storesSetState, idx);

  const isUpdate = title.toLocaleLowerCase().includes("update");

  const { data: { __type: { enumValues: customerStatus } = {} } = {} } =
    useQuery(CUSTOMER_STATUS, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (storeOpen.every((item) => item === false)) {
      storeSetOptions([]);
    }
  }, [storeOpen, storeSetOptions]);

  if (!customerStatus) return <Loading />;
  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              required
              variant="filled"
              fullWidth
              id="name"
              label="Full name"
              name="name"
              value={state.name ?? ""}
              onChange={handleChange()}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              variant="filled"
              fullWidth
              name="contactNumber"
              label="Contact number"
              type="contactNumber"
              id="contactNumber"
              value={state.contactNumber ?? ""}
              onChange={handleChange()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              variant="filled"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={state.email ?? ""}
              onChange={handleChange()}
              type="email"
              disabled={isUpdate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {isUpdate ? (
              <FormControl
                variant="filled"
                fullWidth
                required
                disabled={state?.stores?.length === 0}
              >
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  name="status"
                  value={state.status ?? ""}
                  onChange={handleChange()}
                >
                  {customerStatus?.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                required
                variant="filled"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={state.password ?? ""}
                onChange={handleChange()}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="filled"
              fullWidth
              name="address"
              label="Address"
              type="address"
              id="address"
              value={state.address ?? ""}
              onChange={handleChange()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label htmlFor="validIdPhoto">
              <input
                id="validIdPhoto"
                name="validIdPhoto"
                type="file"
                accept="application/pdf, image/*"
                hidden
                onChange={handleChange()}
              />
              <Button
                component="span"
                variant="outlined"
                fullWidth
                size="small"
              >
                Upload Valid ID
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" align="center">
              {typeof state?.validIdPhoto === "string" ? (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_BACKEND_URI}/${state?.validIdPhoto}`}
                >
                  Valid Id
                </Link>
              ) : (
                state?.validIdPhoto?.name
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <label htmlFor="affidavit">
              <input
                id="affidavit"
                name="affidavit"
                type="file"
                accept="application/pdf, image/*"
                hidden
                onChange={handleChange()}
              />
              <Button
                component="span"
                variant="outlined"
                fullWidth
                size="small"
              >
                Upload Affidavit
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" align="center">
              {typeof state?.affidavit === "string" ? (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_BACKEND_URI}/${state?.affidavit}`}
                >
                  Affidavit
                </Link>
              ) : (
                state?.affidavit?.name
              )}
            </Typography>
          </Grid>
        </Grid>
        {!isUpdate && (
          <Box sx={{ mt: 2 }}>
            <Link
              href="https://publicdownloadable.s3.ap-southeast-1.amazonaws.com/%5BPublic%5DAuthorizationBPDCustPortal.pdf"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Affidavit Template
            </Link>
          </Box>
        )}
        {isUpdate && (
          <>
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1 }}>
              Stores
            </Typography>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              {storesState.map((store, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={11}>
                      <Autocomplete
                        id={`store-${idx}`}
                        name={`store-${idx}`}
                        open={storeOpen[idx]}
                        onOpen={() => {
                          storeSetOpen(
                            storeOpen.map((item, nidx) => {
                              if (idx !== nidx) return item;
                              return !item;
                            })
                          );
                        }}
                        onClose={() => {
                          storeSetOpen(
                            storeOpen.map((item, nidx) => {
                              if (idx !== nidx) return item;
                              return !item;
                            })
                          );
                        }}
                        isOptionEqualToValue={(option, value) => {
                          // if (Object.keys(value).length === 0) return true;
                          return option === value;
                        }}
                        value={store ?? null}
                        onChange={(event, value, reason) => {
                          storesSetState(
                            storesState.map((store, nidx) => {
                              if (idx !== nidx) return store;
                              return { ...value };
                            })
                          );
                        }}
                        onInputChange={(event, value, reason) => {
                          if (event && value) {
                            storeDispatchInput({
                              query: STORES,
                              variables: {
                                where: {
                                  OR: [
                                    {
                                      name: {
                                        contains: value,
                                        mode: "insensitive",
                                      },
                                    },
                                    {
                                      accountCode: {
                                        contains: value,
                                        mode: "insensitive",
                                      },
                                    },
                                  ],
                                },
                              },
                            });
                          }
                        }}
                        getOptionLabel={(option) => {
                          return `${option.name} - ${option.accountCode}` || "";
                        }}
                        options={storeOptions.filter(
                          ({ name }) =>
                            !storesState
                              .filter((item) => item !== null)
                              .some((item) => item.name === name)
                        )}
                        loading={storeLoading[idx]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            required
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {storeLoading[idx] ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        size="small"
                        onClick={() => handleSubtractStore(idx)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
            <Button
              sx={{ float: "right", mt: 1 }}
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleAddStore}
              size="store"
            >
              Add Item
            </Button>
          </>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default CustomerForm;
