import { useState } from "react";

const useQuantityToggle = (defaultValue = 1) => {
  const [quantity, setQuantity] = useState(defaultValue);

  const handleChangeQuantity = (e) => {
    if (e.target.value.match(/^[1-9][0-9]*$/)) {
      setQuantity(+e.target.value);
    }
  };

  const toggleAdd = () => {
    setQuantity((prevState) => prevState + 1);
  };

  const toggleSubtract = () => {
    if (quantity >= 2) {
      setQuantity((prevState) => prevState - 1);
    }
  };

  return { handleChangeQuantity, toggleAdd, toggleSubtract, quantity };
};

export default useQuantityToggle;
