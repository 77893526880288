import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const STORES = gql`
  query Stores(
    $skip: Int
    $take: Int
    $where: StoreWhereInput
    $orderBy: [StoreOrderByInput!]
  ) {
    stores(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      accountCode
      name
      deliveryArea {
        id
        name
      }
      salesRepresentative
      customerType
      tradeDiscount
      terms
    }
  }
`;

const STORES_COUNT = gql`
  query StoresCount($where: StoreWhereInput) {
    storesCount(where: $where)
  }
`;

const DELETE_STORE = gql`
  mutation DeleteStore($where: StoreWhereUniqueInput!) {
    deleteStore(where: $where) {
      accountCode
    }
  }
`;

const DELETE_ALL_STORES = gql`
  mutation DeleteAllStores {
    deleteAllStores
  }
`;

const StoresPage = () => {
  const title = `Stores`;
  const history = useHistory();
  const client = useApolloClient();
  const csvLink = useRef(null);
  const { handleSnackbarOpen } = useSnackbarContext();
  const [exportData, setExportData] = useState([]);
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    OR: [
      {
        accountCode: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        name: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        deliveryArea: {
          name: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      },
      {
        customerType: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
    ],
  };
  const { data: { stores } = {}, loading } = useQuery(STORES, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const [deleteStore] = useMutation(DELETE_STORE, {
    refetchQueries: [{ query: STORES_COUNT }],
    update(cache, { data: { deleteStore } }) {
      cache.modify({
        fields: {
          stores(existingStores, { readField }) {
            return existingStores.filter(
              (store) => deleteStore.id !== readField("id", store)
            );
          },
        },
      });
    },
  });
  const [deleteAllStores] = useMutation(DELETE_ALL_STORES, {
    update(cache, { data: { deleteAllStores } }) {
      cache.modify({
        fields: {
          stores(existingStores) {
            return [];
          },
          storesCount(existingStoresCount) {
            return 0;
          },
        },
      });
    },
    onError: (error) =>
      handleSnackbarOpen({
        message: `Delete all ${title.toLocaleLowerCase()} error! ` + error,
        severity: "error",
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: `Delete all ${title.toLocaleLowerCase()} success!`,
        severity: "success",
      });
    },
  });
  const handleDelete = async (accountCode) => {
    await deleteStore({
      variables: { where: { accountCode } },
    });
  };
  const handleDeleteAll = async () => {
    await deleteAllStores();
  };
  const handleDownload = async () => {
    const { data: { stores } = {} } = await client.query({
      query: STORES,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    setExportData(stores);
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Actions",
      accessor: "accountCode",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Account Code", accessor: "accountCode" },
    { header: "Name", accessor: "name" },
    { header: "Delivery Area", accessor: "deliveryArea.name" },
    { header: "Sales Representative", accessor: "salesRepresentative" },
    { header: "Customer Type", accessor: "customerType" },
    {
      header: "Trade Discount",
      accessor: "tradeDiscount",
      Cell: (children) => children && (children * 100).toFixed(2) + "%",
    },
    { header: "Terms", accessor: "terms" },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Stack direction="row">
          <Button
            onClick={() =>
              handleDialogOpen(
                <AlertDialog
                  handleClose={handleDialogClose}
                  callback={handleDeleteAll}
                  dialogContentText="Are you sure you want to delete all items?"
                />
              )
            }
            variant="contained"
            color="secondary"
          >
            Delete All
          </Button>
          <ButtonGroup
            variant="contained"
            color="secondary"
            className="actionButtons"
          >
            <Button
              onClick={() =>
                history.push(`${history.location.pathname}/upload`)
              }
            >
              Upload
            </Button>
            <Button onClick={handleDownload}>Download</Button>
          </ButtonGroup>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={stores} columns={columns} />
        </Paper>
        <Pagination
          query={STORES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default StoresPage;
