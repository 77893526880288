import React from "react";

const DialogContext = React.createContext();

DialogContext.displayName = "DialogContext";

const DialogProvider = ({ value, ...props }) => (
  <DialogContext.Provider value={value} {...props} />
);

const useDialogContext = () => {
  return React.useContext(DialogContext);
};

export { DialogProvider, useDialogContext };
