import { useState } from "react";

const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(undefined);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleSnackbarOpen = ({ message, severity }) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return {
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    handleSnackbarOpen,
    handleSnackbarClose,
  };
};

export default useSnackbar;
