import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import { useInputChange } from "../../hooks/useInputChange";
import CustomerForm from "../admin/customers/CustomerForm";
import Meta from "../common/Meta";

const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      id
    }
  }
`;

const CreateNewAccountPage = () => {
  const title = `Create New Account`;
  const history = useHistory();
  const { state, handleChange } = useInputChange();
  const { handleSnackbarOpen } = useSnackbarContext();
  const [createCustomer, { loading: mutationLoading }] = useMutation(
    CREATE_CUSTOMER,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.push("/");
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.validIdPhoto || !state.affidavit) {
      handleSnackbarOpen({
        message: `Kindly upload both Valid ID and Affidavit`,
        severity: "error",
      });
    } else {
      await createCustomer({
        variables: {
          data: {
            ...state,
          },
        },
      });
    }
  };
  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <CustomerForm
        title={title}
        state={state}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        mutationLoading={mutationLoading}
      />
    </>
  );
};

export default CreateNewAccountPage;
