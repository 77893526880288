import { gql, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import React from "react";
import { useAuthContext } from "../../contexts/authContext";
import { useSnackbarContext } from "../../contexts/snackbarContext";

const SIGNOUT = gql`
  mutation Signout {
    signout
  }
`;

const SignoutButton = ({ callback = () => {}, variant = "text" }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUserRefetch } = useAuthContext();
  const [signout] = useMutation(SIGNOUT, {
    onError: (error) => {
      handleSnackbarOpen({
        message: "Sign out error! " + error,
        severity: "error",
      });
    },
    onCompleted: () => {
      callback();
      currentUserRefetch();
    },
  });

  const handleClick = async () => {
    await signout();
  };
  return (
    <Button variant={variant} sx={{ mt: 1 }} fullWidth onClick={handleClick}>
      Sign Out
    </Button>
  );
};

export default SignoutButton;
