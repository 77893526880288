import { Stack } from "@mui/material";
import React from "react";
import Meta from "./Meta";

const ContactUsPage = () => {
  const title = `Contact Us`;
  return (
    <>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ flex: 1 }}
        dangerouslySetInnerHTML={{
          __html: `
        <iframe 
        width="640px" 
        height= "480px" 
        src= "https://forms.office.com/Pages/ResponsePage.aspx?id=GzpfsQjkzU6k4y7s8T3vMsn857QFU7ZCsGLTD1HVQMxUMzlGSTY2WTdGRlhUQlM5OE9IR0ZWVTgzMCQlQCN0PWcu&embed=true" 
        frameborder= "0" 
        marginwidth= "0" 
        marginheight= "0" 
        style= "border: none; max-width:100%; max-height:100vh" 
        allowfullscreen 
        webkitallowfullscreen 
        mozallowfullscreen 
        msallowfullscreen> 
        </iframe>
        `,
        }}
      />
    </>
  );
};

export default ContactUsPage;
