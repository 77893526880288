import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#192B60",
    },
    secondary: {
      main: "#BD4931",
    },
    background: {
      default: "#D8D5CC",
    },
  },
});

export default responsiveFontSizes(theme);
