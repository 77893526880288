import React from "react";
import { Redirect, useLocation } from "react-router";
import { useAuthContext } from "../../contexts/authContext";

const AuthGuard = ({ children }) => {
  const location = useLocation();
  const { isStaff, isCustomer } = useAuthContext();

  const protectedAdminPaths = ["admin"];
  const protectedCustomerPaths = ["cart", "orders"];

  const isProtectedAdminPath = protectedAdminPaths.some(
    (path) =>
      location.pathname.includes(path) && !location.pathname.includes("signin")
  );

  const isProtectedCustomerPath = protectedCustomerPaths.some(
    (path) =>
      location.pathname.includes(path) &&
      !location.pathname.includes("signin") &&
      !location.pathname.includes("admin")
  );

  if (isProtectedAdminPath && !isStaff) {
    return <Redirect to="/admin/signin" />;
  }

  if (isProtectedCustomerPath && !isCustomer) {
    return <Redirect to="/signin" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
