import { gql, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router";
import { AD_PLACEMENTS } from "../admin/ad_placements/AdPlacementsPage";
import Loading from "../common/Loading";
import ProductDetail from "./ProductDetail";

const PRODUCT = gql`
  query Product($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      company
      marketingDivision
      itemCode
      itemDescription
      taxSchedule
      listPrice
      businessUnit
      brand
      image
      discount
      unitOfMeasurements {
        id
        multiplierToBasicUnit
        name
        promo {
          id
          buyQuantity
          freeQuantity
        }
      }
    }
  }
`;

const ProductPage = () => {
  let { id } = useParams();
  const { data: { adPlacements } = {}, loading: adPlacementsLoading } =
    useQuery(AD_PLACEMENTS, {
      variables: {
        where: {
          type: {
            equals: "IMAGE",
          },
          location: {
            equals: "PRODUCT_DETAIL_1x2",
          },
        },
      },
    });
  const { data: { product } = {}, loading: productLoading } = useQuery(
    PRODUCT,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          itemCode: id,
        },
      },
    }
  );

  if (productLoading || adPlacementsLoading) return <Loading />;

  return (
    <Grid sx={{ my: "auto" }} container justifyContent="center">
      <Grid item xs={12} lg={10}>
        <ProductDetail {...product} />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Carousel
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          infiniteLoop={true}
          autoPlay={true}
        >
          {adPlacements.map(({ media }, idx) => (
            <img
              key={idx}
              src={`${process.env.REACT_APP_BACKEND_URI}/${media}`}
              alt={`carousel-${idx}`}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default ProductPage;
