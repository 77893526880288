import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import FormContainer from "../../common/FormContainer";
import Loading from "../../common/Loading";
const ROLES = gql`
  query Roles {
    __type(name: "Role") {
      enumValues {
        name
      }
    }
  }
`;
const StaffForm = ({
  title = "",
  state = {},
  handleChange = () => {},
  handleSubmit = () => {},
  mutationLoading = false,
}) => {
  const isUpdate = title.toLocaleLowerCase().includes("update");

  const { data: { __type: { enumValues: roles } = {} } = {} } = useQuery(
    ROLES,
    {
      fetchPolicy: "network-only",
    }
  );
  if (!roles) return <Loading />;
  return (
    <FormContainer maxWidth="xs">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        gutterBottom
      >
        {title}
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              required
              variant="filled"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={state.email ?? ""}
              onChange={handleChange}
              type="email"
              disabled={isUpdate}
              autoFocus
            />
          </Grid>
          {!isUpdate && (
            <Grid item xs={12}>
              <TextField
                required={!isUpdate}
                variant="filled"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={state.password ?? ""}
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth required>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                name="role"
                value={state.role ?? ""}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {roles?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default StaffForm;
