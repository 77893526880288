import { gql, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAutocomplete } from "../../hooks/useAutocomplete";

const DISTINCT_COMPANIES = gql`
  query DistinctCompanies($where: ProductWhereInput) {
    distinctCompanies(where: $where) {
      company
    }
  }
`;

const DISTINCT_BUSINESS_UNITS = gql`
  query DistinctBusinessUnits($where: ProductWhereInput) {
    distinctBusinessUnits(where: $where) {
      businessUnit
    }
  }
`;

const DISTINCT_MARKETING_DIVISIONS = gql`
  query DistinctMarketingDivisions($where: ProductWhereInput) {
    distinctMarketingDivisions(where: $where) {
      marketingDivision
    }
  }
`;

const DISTINCT_BRANDS = gql`
  query DistinctBrands($where: ProductWhereInput) {
    distinctBrands(where: $where) {
      brand
    }
  }
`;

const FiltersDialog = ({
  dialogOpen = false,
  handleDialogClose = () => {},
  handleApplyFilter = () => {},
  company,
  setCompany,
  businessUnit,
  setBusinessUnit,
  marketingDivision,
  setMarketingDivision,
  brand,
  setBrand,
  sortBy,
  setSortBy,
  where = {},
}) => {
  const {
    open: marketingDivisionOpen,
    setOpen: marketingDivisionSetOpen,
    options: marketingDivisionOptions,
    setOptions: marketingDivisionSetOptions,
    loading: marketingDivisionLoading,
    dispatchInput: marketingDivisionDispatchInput,
  } = useAutocomplete();
  const {
    open: brandOpen,
    setOpen: brandSetOpen,
    options: brandOptions,
    setOptions: brandSetOptions,
    loading: brandLoading,
    dispatchInput: brandDispatchInput,
  } = useAutocomplete();
  const {
    data: { distinctCompanies } = {},
    loading: distinctCompaniesLoading,
  } = useQuery(DISTINCT_COMPANIES, {
    variables: {
      where,
    },
  });
  const {
    data: { distinctBusinessUnits } = {},
    loading: distinctBusinessUnitsLoading,
  } = useQuery(DISTINCT_BUSINESS_UNITS, {
    variables: {
      where,
    },
  });

  useEffect(() => {
    if (!marketingDivisionOpen[0]) {
      marketingDivisionSetOptions([]);
    }
  }, [marketingDivisionOpen, marketingDivisionSetOptions]);

  useEffect(() => {
    if (!brandOpen[0]) {
      brandSetOptions([]);
    }
  }, [brandOpen, brandSetOptions]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-describedby="dialog"
      sx={{
        "& .MuiDialog-paper": {
          width: "350px",
          height: "480px",
        },
      }}
    >
      {!distinctCompanies || !distinctBusinessUnits ? (
        <CircularProgress sx={{ m: "auto" }} />
      ) : (
        <>
          <DialogTitle>Search Filters</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="company-label">Company</InputLabel>
                <Select
                  labelId="company-label"
                  id="company"
                  name="company"
                  value={company ?? ""}
                  onChange={(e) => setCompany(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {distinctCompanies.map(({ company }) => (
                    <MenuItem key={company} value={company}>
                      {company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="businessUnit-label">BusinessUnit</InputLabel>
                <Select
                  labelId="businessUnit-label"
                  id="businessUnit"
                  name="businessUnit"
                  value={businessUnit ?? ""}
                  onChange={(e) => setBusinessUnit(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {distinctBusinessUnits.map(({ businessUnit }) => (
                    <MenuItem key={businessUnit} value={businessUnit}>
                      {businessUnit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                id="marketingDivision"
                name="marketingDivision"
                sx={{ width: 300 }}
                open={marketingDivisionOpen[0]}
                onOpen={() => {
                  marketingDivisionSetOpen([true]);
                }}
                onClose={() => {
                  marketingDivisionSetOpen([false]);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                value={marketingDivision ?? null}
                onChange={(event, value, reason) => {
                  setMarketingDivision(value);
                }}
                onInputChange={(event, value, reason) => {
                  if (event && value) {
                    marketingDivisionDispatchInput({
                      query: DISTINCT_MARKETING_DIVISIONS,
                      variables: {
                        where: {
                          ...where,
                          marketingDivision: {
                            contains: value,
                            mode: "insensitive",
                          },
                        },
                      },
                    });
                  }
                }}
                getOptionLabel={(option) => option}
                options={marketingDivisionOptions.map(
                  ({ marketingDivision }) => marketingDivision
                )}
                loading={marketingDivisionLoading[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Marketing division"
                    placeholder="Type to search..."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {marketingDivisionLoading[0] ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Autocomplete
                id="brand"
                name="brand"
                sx={{ width: 300 }}
                open={brandOpen[0]}
                onOpen={() => {
                  brandSetOpen([true]);
                }}
                onClose={() => {
                  brandSetOpen([false]);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                value={brand ?? null}
                onChange={(event, value, reason) => {
                  setBrand(value);
                }}
                onInputChange={(event, value, reason) => {
                  if (event && value) {
                    brandDispatchInput({
                      query: DISTINCT_BRANDS,
                      variables: {
                        where: {
                          ...where,
                          brand: {
                            contains: value,
                            mode: "insensitive",
                          },
                        },
                      },
                    });
                  }
                }}
                getOptionLabel={(option) => option}
                options={brandOptions.map(({ brand }) => brand)}
                loading={brandLoading[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Brand"
                    placeholder="Type to search..."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {brandLoading[0] ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <FormControl variant="filled" fullWidth>
                <InputLabel id="sortBy-label">Sort by</InputLabel>
                <Select
                  labelId="sortBy-label"
                  id="sortBy"
                  name="sortBy"
                  value={sortBy ?? "Item Description A-Z"}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <MenuItem value="Item Description A-Z">
                    Item Description A-Z
                  </MenuItem>
                  <MenuItem value="Marketing Div A-Z">
                    Marketing Div A-Z
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleApplyFilter}>Apply</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default FiltersDialog;
