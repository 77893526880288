import { Divider, Stack, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router";

const Footer = () => {
  const location = useLocation();
  if (location.pathname.includes("admin")) return null;
  return (
    <Box
      component="footer"
      sx={{
        p: 2,
        bgcolor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.common.white,
      }}
    >
      <Typography variant="h6" align="center">
        Bestline Pharma Distribution
      </Typography>
      <Typography variant="body1" align="center" sx={{ mb: 2 }}>
        Carmona, Cavite
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            light={true}
            sx={{ borderColor: (theme) => theme.palette.common.white }}
          />
        }
        spacing={1}
      >
        <Stack direction="row" alignItems="center">
          <PhoneIcon fontSize="small" sx={{ mr: 0.5, fontSize: "16px" }} />
          <Typography variant="body2">+63 46 430 3070</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box
            component="span"
            sx={{ fontWeight: "bold", fontSize: "16px", mr: 0.5 }}
          >
            G
          </Box>
          <Typography variant="body2">+63 917 848 9240</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box
            component="span"
            sx={{ fontWeight: "bold", fontSize: "16px", mr: 0.5 }}
          >
            S
          </Box>
          <Typography variant="body2"> +63 933 868 8474</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <EmailIcon fontSize="small" sx={{ mr: 0.5, fontSize: "16px" }} />
          <Typography variant="body2">bpd.info@chua-group.com</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
