import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import { useInputChange } from "../../hooks/useInputChange";
import FormContainer from "../common/FormContainer";
import Meta from "../common/Meta";
import { CURRENT_USER } from "../page/Page";

const SIGN_IN = gql`
  mutation Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      email
      staff {
        id
      }
      customer {
        id
      }
    }
  }
`;

const SigninPage = ({ type = "Customer" }) => {
  const title = `${type} Sign In`;
  const history = useHistory();
  const { state, handleChange } = useInputChange();
  const { handleSnackbarOpen } = useSnackbarContext();
  const [showPassword, setShowPassword] = useState(false);
  const [signin, { loading: mutationLoading }] = useMutation(SIGN_IN, {
    refetchQueries: [{ query: CURRENT_USER }],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleSnackbarOpen({
        message:
          title.charAt(0).toUpperCase() +
          title.slice(1).toLowerCase() +
          " error! " +
          error,
        severity: "error",
      }),
    onCompleted: ({ signin }) => {
      if (signin?.staff?.id) {
        history.push("/admin");
      } else {
        history.push("/");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    signin({ variables: { ...state } });
  };

  return (
    <>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <FormContainer maxWidth="xs">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                variant="filled"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange()}
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth required>
                <InputLabel htmlFor="password">Password</InputLabel>
                <FilledInput
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  onChange={handleChange()}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 1 }}
            disabled={mutationLoading}
          >
            Sign In
          </Button>
          <Button
            fullWidth
            sx={{ mt: 1, mb: 1 }}
            onClick={() => history.push("/request-password-reset")}
          >
            Forgot Your Password?
          </Button>
          {type === "Customer" && (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              color="secondary"
              onClick={() => history.push(`/create-new-account`)}
            >
              Create New Account
            </Button>
          )}
        </Box>
      </FormContainer>
    </>
  );
};

export default SigninPage;
