import { Button, InputBase, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";

const QuantityToggle = ({
  toggleAdd,
  toggleSubtract,
  quantity,
  handleChangeQuantity,
  disabled = false,
}) => {
  return (
    <>
      <Stack direction="row" sx={{ height: "100%" }}>
        <Button
          disabled={disabled}
          disableElevation
          variant="contained"
          color="secondary"
          onClick={toggleSubtract}
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <RemoveIcon />
        </Button>
        <InputBase
          disabled={disabled}
          variant="outlined"
          value={quantity}
          onChange={handleChangeQuantity}
          sx={{
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            width: "100%",
            minWidth: "60px",
            "& .MuiInputBase-input": {
              textAlign: "center",
            },
          }}
        />
        <Button
          disabled={disabled}
          disableElevation
          variant="contained"
          color="secondary"
          onClick={toggleAdd}
          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <AddIcon />
        </Button>
      </Stack>
    </>
  );
};

export default QuantityToggle;
