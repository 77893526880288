import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FormContainer from "../../common/FormContainer";
import Loading from "../../common/Loading";

const AD_PLACEMENT_TYPES = gql`
  query AdPlacementTypes {
    __type(name: "AdPlacementType") {
      enumValues {
        name
      }
    }
  }
`;

const AD_PLACEMENT_LOCATIONS = gql`
  query AdPlacementLocations {
    __type(name: "AdPlacementLocation") {
      enumValues {
        name
      }
    }
  }
`;

const AdPlacementForm = ({
  title = "",
  state = {},
  handleChange = () => {},
  handleSubmit = () => {},
  mutationLoading = false,
}) => {
  const { data: { __type: { enumValues: adPlacementTypes } = {} } = {} } =
    useQuery(AD_PLACEMENT_TYPES, {
      fetchPolicy: "network-only",
    });

  const { data: { __type: { enumValues: adPlacementLocations } = {} } = {} } =
    useQuery(AD_PLACEMENT_LOCATIONS, {
      fetchPolicy: "network-only",
    });
  if (!adPlacementTypes || !adPlacementLocations) return <Loading />;
  return (
    <FormContainer maxWidth="xs">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        gutterBottom
      >
        {title}
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth required>
              <InputLabel id="location-label">Ad Placement Location</InputLabel>
              <Select
                labelId="location-label"
                id="location"
                name="location"
                value={state.location ?? ""}
                onChange={handleChange}
                autoFocus
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {adPlacementLocations?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name.replace(/_/g, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth required>
              <InputLabel id="type-label">Ad Palcement Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                name="type"
                value={state.type ?? ""}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {adPlacementTypes?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <label htmlFor="media">
              <input
                id="media"
                name="media"
                type="file"
                accept="video/*, image/*"
                hidden
                onChange={handleChange}
              />
              <Button
                component="span"
                variant="outlined"
                fullWidth
                size="small"
              >
                Upload Media
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" align="center">
              {typeof state?.media === "string" ? (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_BACKEND_URI}/${state?.media}`}
                >
                  Media
                </Link>
              ) : (
                state?.media?.name
              )}
            </Typography>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          disabled={mutationLoading}
        >
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default AdPlacementForm;
