const handleNestedAdd = (state, setState, value) => {
  setState(state.concat(value));
};
const handleNestedSubtract = (state, setState, idx) => {
  setState(state.filter((item, nidx) => idx !== nidx));
};
const handleNestedChange = (state, setState, idx) => (e) => {
  const target = e.target;
  const name = target.name;
  let value;

  const newItems = state.map((item, nidx) => {
    if (idx !== nidx) return item;
    return { ...item, [name]: value };
  });

  setState(newItems);
};

export { handleNestedAdd, handleNestedSubtract, handleNestedChange };
