import { gql, useMutation } from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import { useInputChange } from "../../hooks/useInputChange";
import FormContainer from "../common/FormContainer";
import Meta from "../common/Meta";
import { CURRENT_USER } from "../page/Page";

const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $resetToken: String!) {
    resetPassword(password: $password, resetToken: $resetToken) {
      id
      staff {
        id
      }
    }
  }
`;

const ResetPasswordPage = () => {
  const title = `Reset Password`;
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const resetToken = parsed["resetToken"];

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { state, handleChange } = useInputChange();
  const { handleSnackbarOpen } = useSnackbarContext();
  const [resetPassword, { loading: mutationLoading }] = useMutation(
    RESET_PASSWORD,
    {
      refetchQueries: [{ query: CURRENT_USER }],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: ({ resetPassword }) => {
        if (resetPassword?.staff?.id) {
          history.push("/admin");
        } else {
          history.push("/");
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.password !== state.confirmPassword) {
      handleSnackbarOpen({
        message:
          title.charAt(0).toUpperCase() +
          title.slice(1).toLowerCase() +
          "Error! Passwords do not match",
        severity: "error",
      });
    } else {
      resetPassword({ variables: { password: state.password, resetToken } });
    }
  };

  return (
    <>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <FormContainer maxWidth="xs">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth required>
                <InputLabel htmlFor="password">Password</InputLabel>
                <FilledInput
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  onChange={handleChange()}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth required>
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <FilledInput
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  onChange={handleChange()}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 1 }}
            disabled={mutationLoading}
          >
            Reset Password
          </Button>
        </Box>
      </FormContainer>
    </>
  );
};

export default ResetPasswordPage;
