import { useInputChange } from "../../hooks/useInputChange";

const sortUnitOfMeasurements = (unitOfMeasurements = []) =>
  [...unitOfMeasurements].sort(
    (a, b) => (a.multiplierToBasicUnit > b.multiplierToBasicUnit && 1) || -1
  );
const useProductInputChange = (
  unitOfMeasurementId = null,
  sortedUnitOfMeasurements = []
) => {
  const { state, handleChange } = useInputChange(
    {},
    {
      unitOfMeasurement: unitOfMeasurementId
        ? sortedUnitOfMeasurements.find(({ id }) => id === unitOfMeasurementId)
        : sortedUnitOfMeasurements[0],
    }
  );

  return { state, handleChange };
};

const computeOriginalPrice = (
  listPrice,
  multiplierToBasicUnit,
  taxSchedule
) => {
  return (
    listPrice *
    multiplierToBasicUnit *
    (taxSchedule === "VAT Exempt" ? 1 : 1.12)
  );
};

const computeDiscountPrice = (
  listPrice,
  multiplierToBasicUnit,
  taxSchedule,
  discount
) => {
  return (
    listPrice *
    multiplierToBasicUnit *
    (taxSchedule === "VAT Exempt" ? 1 : 1.12) *
    (discount ? 1 - discount : 1)
  );
};

export {
  useProductInputChange,
  computeOriginalPrice,
  computeDiscountPrice,
  sortUnitOfMeasurements,
};
