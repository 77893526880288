import React from "react";
import { Route, Switch } from "react-router-dom";
import AdPlacementsPage from "../admin/ad_placements/AdPlacementsPage";
import CreateAdPlacementPage from "../admin/ad_placements/CreateAdPlacementPage";
import CustomersPage from "../admin/customers/CustomersPage";
import UpdateCustomerPage from "../admin/customers/UpdateCustomerPage";
import DeliveryAreasPage from "../admin/delivery_areas/DeliveryAreasPage";
import DeliverySchedulesPage from "../admin/delivery_schedules/DeliverySchedulesPage";
import UploadDeliverySchedulesPage from "../admin/delivery_schedules/UploadDeliverySchedulesPage";
import AdminProductsPage from "../admin/products/ProductsPage";
import UploadProductsPage from "../admin/products/UploadProductsPage";
import PromosPage from "../admin/promos/PromosPage";
import UploadPromosPage from "../admin/promos/UploadPromosPage";
import SalesInvoicesPage from "../admin/sales_invoices/SalesInvoicesPage";
import UploadSalesInvoicesPage from "../admin/sales_invoices/UploadSalesInvoicesPage";
import CreateStaffPage from "../admin/staffs/CreateStaffPage";
import StaffsPage from "../admin/staffs/StaffsPage";
import UpdateStaffPage from "../admin/staffs/UpdateStaffPage";
import StoresPage from "../admin/stores/StoresPage";
import UploadStoresPage from "../admin/stores/UploadStoresPage";
import UnitOfMeasurementsPage from "../admin/unit_of_measurements/UnitOfMeasurementsPage";
import UploadUnitOfMeasurementsPage from "../admin/unit_of_measurements/UploadUnitOfMeasurementsPage";
import CreateNewAccountPage from "../authentication/CreateNewAccountPage";
import SigninPage from "../authentication/SigninPage";
import CartPage from "../cart/CartPage";
import OrderConfirmationPage from "../cart/OrderConfirmationPage";
import ContactUsPage from "../common/ContactUsPage";
import HomePage from "../common/HomePage";
import OrdersPage from "../orders/OrdersPage";
import AdminOrdersPage from "../admin/orders/OrdersPage";
import ProductPage from "../products/ProductPage";
import ProductsPage from "../products/ProductsPage";
import ViewOrderPage from "../admin/orders/ViewOrderPage";
import AdminPage from "../admin/AdminPage";
import RequestPasswordResetPage from "../authentication/RequestPasswordResetPage";
import ResetPasswordPage from "../authentication/ResetPasswordPage";
import OrderSummaryPage from "../orders/OrderSummaryPage";

const Routes = () => {
  return (
    <Switch>
      {/* CUSTOMER ROUTES */}
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/products">
        <ProductsPage />
      </Route>
      <Route path="/products/:id">
        <ProductPage />
      </Route>
      <Route path="/signin">
        <SigninPage />
      </Route>
      <Route path="/request-password-reset">
        <RequestPasswordResetPage />
      </Route>
      <Route path="/reset-password">
        <ResetPasswordPage />
      </Route>
      <Route path="/contact-us">
        <ContactUsPage />
      </Route>
      <Route path="/cart">
        <CartPage />
      </Route>
      <Route path="/order-summary">
        <OrderSummaryPage />
      </Route>
      <Route path="/create-new-account">
        <CreateNewAccountPage />
      </Route>
      <Route exact path="/orders">
        <OrdersPage />
      </Route>
      <Route path="/orders/:orderNumber">
        <ViewOrderPage />
      </Route>
      <Route path="/order-confirmation/:orderNumber">
        <OrderConfirmationPage />
      </Route>
      {/* ADMIN ROUTES */}
      <Route exact path="/admin">
        <AdminPage />
      </Route>
      <Route path="/admin/signin">
        <SigninPage type="Admin" />
      </Route>
      <Route path="/admin/reset-password">
        <ResetPasswordPage />
      </Route>
      <Route exact path="/admin/staffs">
        <StaffsPage />
      </Route>
      <Route path="/admin/staffs/create">
        <CreateStaffPage />
      </Route>
      <Route path="/admin/staffs/:id">
        <UpdateStaffPage />
      </Route>
      <Route exact path="/admin/users">
        <CustomersPage />
      </Route>
      <Route path="/admin/users/:id">
        <UpdateCustomerPage />
      </Route>
      <Route exact path="/admin/delivery-areas">
        <DeliveryAreasPage />
      </Route>
      <Route exact path="/admin/delivery-schedules">
        <DeliverySchedulesPage />
      </Route>
      <Route path="/admin/delivery-schedules/upload">
        <UploadDeliverySchedulesPage />
      </Route>
      <Route exact path="/admin/stores">
        <StoresPage />
      </Route>
      <Route path="/admin/stores/upload">
        <UploadStoresPage />
      </Route>
      <Route exact path="/admin/products">
        <AdminProductsPage />
      </Route>
      <Route path="/admin/products/upload">
        <UploadProductsPage />
      </Route>
      <Route exact path="/admin/unit-of-measurements">
        <UnitOfMeasurementsPage />
      </Route>
      <Route path="/admin/unit-of-measurements/upload">
        <UploadUnitOfMeasurementsPage />
      </Route>
      <Route exact path="/admin/promos">
        <PromosPage />
      </Route>
      <Route path="/admin/promos/upload">
        <UploadPromosPage />
      </Route>
      <Route exact path="/admin/ad-placements">
        <AdPlacementsPage />
      </Route>
      <Route path="/admin/ad-placements/create">
        <CreateAdPlacementPage />
      </Route>
      <Route exact path="/admin/sales-invoices">
        <SalesInvoicesPage />
      </Route>
      <Route path="/admin/sales-invoices/upload">
        <UploadSalesInvoicesPage />
      </Route>
      <Route exact path="/admin/orders">
        <AdminOrdersPage />
      </Route>
      <Route path="/admin/orders/:orderNumber">
        <ViewOrderPage />
      </Route>
    </Switch>
  );
};

export default Routes;
