import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../images/logos/logo112x100.png";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg)
  }
`;
const StyledImage = styled.img`
  animation: ${spin} 3s linear infinite;
  width: 112px;
  height: 100px;
  margin: auto;
`;

const AdminPage = () => {
  return <StyledImage src={logo} alt="logo" />;
};

export default AdminPage;
