import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

const DELIVERY_SCHEDULES = gql`
  query DeliverySchedules(
    $skip: Int
    $take: Int
    $where: DeliveryScheduleWhereInput
    $orderBy: [DeliveryScheduleOrderByInput!]
  ) {
    deliverySchedules(
      skip: $skip
      take: $take
      where: $where
      orderBy: $orderBy
    ) {
      id
      date
      deliveryArea {
        id
        name
      }
    }
  }
`;

const DELIVERY_SCHEDULES_COUNT = gql`
  query DeliverySchedulesCount($where: DeliveryScheduleWhereInput) {
    deliverySchedulesCount(where: $where)
  }
`;

const DELETE_DELIVERY_SCHEDULE = gql`
  mutation DeleteDeliverySchedule($where: DeliveryScheduleWhereUniqueInput!) {
    deleteDeliverySchedule(where: $where) {
      id
    }
  }
`;

const DELETE_ALL_DELIVERY_SCHEDULES = gql`
  mutation DeleteAllDeliverySchedules {
    deleteAllDeliverySchedules
  }
`;

const DeliverySchedulesPage = () => {
  const title = `Delivery Schedules`;
  const client = useApolloClient();
  const history = useHistory();
  const { handleSnackbarOpen } = useSnackbarContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    deliveryArea: {
      name: {
        contains: searchQueryString,
        mode: "insensitive",
      },
    },
  };
  const { data: { deliverySchedules } = {}, loading } = useQuery(
    DELIVERY_SCHEDULES,
    {
      fetchPolicy: "network-only",
      variables: {
        skip: page * perPage - perPage,
        take: perPage,
        where,
        orderBy: {
          date: "desc",
        },
      },
    }
  );
  const [deleteAllDeliverySchedules] = useMutation(
    DELETE_ALL_DELIVERY_SCHEDULES,
    {
      update(cache, { data: { deleteAllDeliverySchedules } }) {
        cache.modify({
          fields: {
            deliverySchedules(existingDeliverySchedules) {
              return [];
            },
            deliverySchedulesCount(existingDeliverySchedulesCount) {
              return 0;
            },
          },
        });
      },
      onError: (error) =>
        handleSnackbarOpen({
          message: `Delete all ${title.toLocaleLowerCase()} error! ` + error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message: `Delete all ${title.toLocaleLowerCase()} success!`,
          severity: "success",
        });
      },
    }
  );
  const [deleteDeliverySchedule] = useMutation(DELETE_DELIVERY_SCHEDULE, {
    refetchQueries: [{ query: DELIVERY_SCHEDULES_COUNT }],
    update(cache, { data: { deleteDeliverySchedule } }) {
      cache.modify({
        fields: {
          deliverySchedules(existingDeliverySchedules, { readField }) {
            return existingDeliverySchedules.filter(
              (deliverySchedule) =>
                deleteDeliverySchedule.id !== readField("id", deliverySchedule)
            );
          },
        },
      });
    },
  });
  const handleDeleteAll = async () => {
    await deleteAllDeliverySchedules();
  };
  const handleDelete = async (id) => {
    await deleteDeliverySchedule({
      variables: { where: { id } },
    });
  };

  const handleDownload = async () => {
    const { data: { deliverySchedules } = {} } = await client.query({
      query: DELIVERY_SCHEDULES,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    setExportData(deliverySchedules);
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Area", accessor: "deliveryArea.name" },
    {
      header: "Date",
      accessor: "date",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Stack direction="row">
          <Button
            onClick={() =>
              handleDialogOpen(
                <AlertDialog
                  handleClose={handleDialogClose}
                  callback={handleDeleteAll}
                  dialogContentText="Are you sure you want to delete all items?"
                />
              )
            }
            variant="contained"
            color="secondary"
          >
            Delete All
          </Button>
          <ButtonGroup
            variant="contained"
            color="secondary"
            className="actionButtons"
          >
            <Button
              onClick={() =>
                history.push(`${history.location.pathname}/upload`)
              }
            >
              Upload
            </Button>
            <Button onClick={handleDownload}>Download</Button>
          </ButtonGroup>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={deliverySchedules} columns={columns} />
        </Paper>
        <Pagination
          query={DELIVERY_SCHEDULES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default DeliverySchedulesPage;
