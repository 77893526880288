import { gql, useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import CSVReaderComponent from "../../common/CSVReaderComponent";
import FormContainer from "../../common/FormContainer";
import Meta from "../../common/Meta";

const UPLOAD_PROMOS = gql`
  mutation UploadPromos($data: [PromoCreateInput]!) {
    uploadPromos(data: $data)
  }
`;

const UploadPromosPage = () => {
  const title = `Upload Promos`;
  const history = useHistory();
  const [parsedData, setParsedData] = useState([]);
  const { handleSnackbarOpen } = useSnackbarContext();
  const [uploadPromos, { loading: mutationLoading }] = useMutation(
    UPLOAD_PROMOS,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.push("/admin/promos");
      },
    }
  );
  const handleOnDrop = (data) => {
    setParsedData(
      data.filter(({ errors }) => errors.length === 0).map(({ data }) => data)
    );
  };
  const handleOnError = (err, file, inputElem, reason) => {
    handleSnackbarOpen({
      message: "CSV parse error! " + err,
      severity: "error",
    });
  };
  const handleOnRemoveFile = () => {
    setParsedData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await uploadPromos({
      variables: {
        data: parsedData.map(
          ({ itemCode, name, buyQuantity, freeQuantity }) => {
            return {
              buyQuantity: parseInt(buyQuantity),
              freeQuantity: parseInt(freeQuantity),
              unitOfMeasurement: {
                connect: {
                  productId_name: {
                    productId: itemCode,
                    name,
                  },
                },
              },
            };
          }
        ),
      },
    });
  };

  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <FormContainer maxWidth="xs">
        <Typography
          component="h1"
          variant="h4"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <CSVReaderComponent
            handleOnDrop={handleOnDrop}
            handleOnError={handleOnError}
            handleOnRemoveFile={handleOnRemoveFile}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={parsedData.length === 0 || mutationLoading}
          >
            Submit
          </Button>
        </Box>
      </FormContainer>
    </>
  );
};

export default UploadPromosPage;
