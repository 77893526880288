import Button from "@mui/material/Button";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid, TextField } from "@mui/material";

const DateFilter = ({
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  handleClearDateFilters,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <DatePicker
            id="startDate"
            name="startDate"
            label="Start Date"
            value={startDate || null}
            onChange={handleChangeStartDate}
            disableMaskedInput
            inputProps={{ readOnly: true }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          <DatePicker
            id="endDate"
            name="endDate"
            label="End Date"
            value={endDate || null}
            onChange={handleChangeEndDate}
            inputProps={{ readOnly: true }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  borderRadius: "4px",
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Button onClick={handleClearDateFilters}>Clear</Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DateFilter;
