import { gql, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import PageListView from "../../../styles/pages/PageListView";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";

export const AD_PLACEMENTS = gql`
  query AdPlacements($where: AdPlacementWhereInput) {
    adPlacements(where: $where) {
      id
      location
      media
      type
    }
  }
`;

const DELETE_AD_PLACEMENT = gql`
  mutation DeleteAdPlacement($where: AdPlacementWhereUniqueInput!) {
    deleteAdPlacement(where: $where) {
      id
    }
  }
`;
const AdPlacementsPage = () => {
  const title = `Ad Placements`;
  const history = useHistory();
  const { handleDialogOpen, handleDialogClose } = useDialogContext();

  const { data: { adPlacements } = {}, loading } = useQuery(AD_PLACEMENTS, {
    fetchPolicy: "network-only",
  });

  const [deleteAdPlacement] = useMutation(DELETE_AD_PLACEMENT, {
    update(cache, { data: { deleteAdPlacement } }) {
      cache.modify({
        fields: {
          adPlacements(existingAdPlacements, { readField }) {
            return existingAdPlacements.filter(
              (adPlacement) =>
                deleteAdPlacement.id !== readField("id", adPlacement)
            );
          },
        },
      });
    },
  });

  const handleDelete = async (id) => {
    await deleteAdPlacement({
      variables: { where: { id } },
    });
  };
  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Type", accessor: "type" },
    {
      header: "Location",
      accessor: "location",
      Cell: (children) => children && children.replace(/_/g, " "),
    },
    {
      header: "Media",
      accessor: "media",
      Cell: (children) =>
        children && (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BACKEND_URI}/${children}`}
          >
            {children}
          </Link>
        ),
    },
  ];
  if (loading) return <Loading />;
  return (
    <PageListView>
      <Button
        variant="contained"
        color="secondary"
        className="actionButton"
        onClick={() => history.push(`${history.location.pathname}/create`)}
      >
        Create
      </Button>
      <Typography variant="h3" color="primary">
        {title}
      </Typography>
      <Paper className="dataTableContainer">
        <DataTable data={adPlacements} columns={columns} />
      </Paper>
    </PageListView>
  );
};

export default AdPlacementsPage;
