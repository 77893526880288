import { gql, useQuery } from "@apollo/client";
import {
  Card,
  CardActionArea,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import queryString from "query-string";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import { useInputChange } from "../../hooks/useInputChange";
import useQueryString from "../../hooks/useQueryString";
import PageListView from "../../styles/pages/PageListView";
import formatMoney from "../../utils/formatMoney";
import Loading from "../common/Loading";
import Meta from "../common/Meta";
import Pagination from "../common/Pagination";

export const ORDERS = gql`
  query Orders(
    $skip: Int
    $take: Int
    $where: OrderWhereInput
    $orderBy: [OrderOrderByInput!]
  ) {
    orders(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      orderNumber
      createdAt
      netTotal
      status
      typeOfApproval
      storeName
      storeAccountCode
    }
  }
`;

const ORDERS_COUNT = gql`
  query OrdersCount($where: OrderWhereInput) {
    ordersCount(where: $where)
  }
`;

const OrdersPage = () => {
  const title = `Orders`;
  const { currentUser } = useAuthContext();
  const stores = currentUser?.customer?.stores || [];
  const history = useHistory();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 20;

  const { state, handleChange } = useInputChange();
  const {
    handleSubmitQueryString: handleSubmitFilter,
    initialQueryStringValue: filterQueryString,
  } = useQueryString("filter");
  const customerWhere = {
    customerName: {
      equals: currentUser?.customer?.name,
    },
  };
  const storeWhere = filterQueryString && {
    storeAccountCode: {
      equals: filterQueryString,
    },
  };
  const where = {
    ...customerWhere,
    ...storeWhere,
  };
  const { data: { orders } = {} } = useQuery(ORDERS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: {
        createdAt: "desc",
      },
    },
  });

  if (!orders) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <PageListView>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          sx={{ my: 1 }}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <FormControl variant="outlined" size="small">
            <InputLabel id="store-label">Store</InputLabel>
            <Select
              labelId="store-label"
              label="Store"
              id="store"
              name="store"
              value={state?.store?.accountCode ?? ""}
              onChange={(e) => {
                handleChange(stores, "accountCode")(e);
                handleSubmitFilter("direct")(e);
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: "320px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stores.map(({ accountCode, name }) => (
                <MenuItem key={accountCode} value={accountCode}>
                  {accountCode} - {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {orders.length > 0 ? (
          orders.map(
            ({
              orderNumber,
              createdAt,
              netTotal,
              status,
              typeOfApproval,
              storeName,
              storeAccountCode,
            }) => (
              <Card key={orderNumber} variant="outlined" sx={{ my: 1 }}>
                <CardActionArea
                  sx={{ p: 2 }}
                  onClick={() => history.push(`/orders/${orderNumber}`)}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={6}>
                      <Stack
                        spacing={1}
                        sx={{
                          my: {
                            xs: 1,
                            md: 0,
                          },
                        }}
                      >
                        {!filterQueryString && (
                          <Typography variant="h6" sx={{ fontWeight: 400 }}>
                            <Box component="span" sx={{ fontWeight: "bold" }}>
                              Store:
                            </Box>{" "}
                            {storeAccountCode} - {storeName}
                          </Typography>
                        )}
                        <Typography variant="h6" sx={{ fontWeight: 400 }}>
                          <Box component="span" sx={{ fontWeight: "bold" }}>
                            Order Number:
                          </Box>{" "}
                          {orderNumber}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400 }}>
                          <Box component="span" sx={{ fontWeight: "bold" }}>
                            Request Date:
                          </Box>{" "}
                          {format(
                            new Date(createdAt),
                            "MMM dd, yyyy HH:mm:ss b"
                          )}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 400 }}>
                          <Box component="span" sx={{ fontWeight: "bold" }}>
                            Total:
                          </Box>{" "}
                          {formatMoney(netTotal)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          align="center"
                          sx={{
                            color: (theme) => theme.palette.common.white,
                            bgcolor: (theme) => theme.palette.secondary.main,
                            borderRadius: "8px",
                            p: 0.5,
                          }}
                        >
                          {status.replace(/_/g, " ")}{" "}
                          {typeOfApproval && `- ${typeOfApproval}`}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            )
          )
        ) : (
          <Paper sx={{ p: 2 }} variant="outlined">
            <Typography variant="h6" align="center">
              No items here.
            </Typography>
          </Paper>
        )}
        <Pagination
          query={ORDERS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default OrdersPage;
