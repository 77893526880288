import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useAuthContext } from "../../contexts/authContext";
import Meta from "../common/Meta";
import Product from "../products/Product";
import OrderSummary from "./OrderSummary";

const CartPage = () => {
  const title = `My Cart`;
  const { currentUser } = useAuthContext();
  const cartItems = currentUser?.customer?.cartItems
    .map((cartItem) => {
      return {
        ...cartItem,
      };
    })
    .sort((a, b) => (a.id > b.id && 1) || -1);

  return (
    <Box sx={{ my: 1 }}>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <Typography variant="h3" color="primary" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            {cartItems.length > 0 ? (
              cartItems.map(({ unitOfMeasurement, quantity, id }) => {
                const { product } = unitOfMeasurement;
                return (
                  <Product
                    key={id}
                    {...product}
                    quantity={quantity}
                    unitOfMeasurementId={unitOfMeasurement.id}
                    cartItemId={id}
                    isCartPage={true}
                  />
                );
              })
            ) : (
              <Paper sx={{ py: 2 }}>
                <Typography variant="h6" align="center">
                  No items here
                </Typography>
              </Paper>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <OrderSummary currentUser={currentUser} cartItems={cartItems} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartPage;
