import React from "react";
import { Helmet } from "react-helmet-async";
const Meta = ({ title, description, meta = [] }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={[{ name: `description`, content: description }, ...meta]}
    />
  );
};

export default Meta;
