import { styled } from "@mui/material";

const PageListView = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0),
  flex: 1,
  display: "flex",
  flexDirection: "column",
  "& .actionButton, .actionButtons": {
    display: "block",
    marginLeft: "auto",
  },

  "& .dataTableContainer": {
    overflowX: "auto",
    overflowY: "hidden",
    margin: theme.spacing(1, 0),
  },
}));

export default PageListView;
