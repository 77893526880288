import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  Paper,
  Portal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import formatMoney from "../../../utils/formatMoney";
import iterateObjectValuesNullToBlank from "../../../utils/iterateObjectValuesNullToBlank";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import NestedTable from "../../common/NestedTable";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

const SALES_INVOICES = gql`
  query SalesInvoices(
    $skip: Int
    $take: Int
    $where: SalesInvoiceWhereInput
    $orderBy: [SalesInvoiceOrderByInput!]
  ) {
    salesInvoices(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      transactionNumber
      order {
        orderNumber
      }
      transactionDate
      storeAccountCode
      storeName
      expectedDeliveryDate
      total
      salesInvoiceItems {
        id
        itemCode
        quantity
        unitOfMeasurementName
        listPrice
        gross
        vat
        netBeforeTradeDiscount
        subtotal
      }
    }
  }
`;

const SALES_INVOICES_COUNT = gql`
  query SalesInvoicesCount($where: SalesInvoiceWhereInput) {
    salesInvoicesCount(where: $where)
  }
`;

const DELETE_SALES_INVOICE = gql`
  mutation DeleteSalesInvoice($where: SalesInvoiceWhereUniqueInput!) {
    deleteSalesInvoice(where: $where) {
      transactionNumber
    }
  }
`;
const SalesInvoicesPage = () => {
  const title = `Sales Invoices`;
  const history = useHistory();
  const client = useApolloClient();
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const [collapse, setCollapse] = useState([]);
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 25;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    OR: [
      {
        order: {
          orderNumber: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      },
      {
        transactionNumber: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        storeAccountCode: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        storeName: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
    ],
  };
  const { data: { salesInvoices } = {}, loading } = useQuery(SALES_INVOICES, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const [deleteSalesInvoice] = useMutation(DELETE_SALES_INVOICE, {
    refetchQueries: [{ query: SALES_INVOICES_COUNT }],
    update(cache, { data: { deleteSalesInvoice } }) {
      cache.modify({
        fields: {
          salesInvoices(existingSalesInvoices, { readField }) {
            return existingSalesInvoices.filter(
              (salesInvoice) =>
                deleteSalesInvoice.transactionNumber !==
                readField("transactionNumber", salesInvoice)
            );
          },
        },
      });
    },
  });

  const handleDelete = async (transactionNumber) => {
    await deleteSalesInvoice({
      variables: { where: { transactionNumber } },
    });
  };

  const handleDownload = async () => {
    const { data: { salesInvoices } = {} } = await client.query({
      query: SALES_INVOICES,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    const transformedOrders = salesInvoices.reduce(
      (acc, { salesInvoiceItems, ...cur }) => {
        return [
          ...acc,
          ...salesInvoiceItems.map((salesInvoiceItem) => {
            return { ...salesInvoiceItem, ...cur };
          }),
        ];
      },
      []
    );
    setExportData(
      transformedOrders.map(({ expectedDeliveryDate, ...item }) => {
        return {
          ...iterateObjectValuesNullToBlank(item),
          expectedDeliveryDate: format(
            new Date(expectedDeliveryDate),
            "MM/dd/yyyy"
          ),
        };
      })
    );
    csvLink.current.link.click();
  };
  const salesInvoiceItemsColumns = [
    {
      header: "Item Code",
      accessor: "itemCode",
    },
    {
      header: "Quantity",
      accessor: "quantity",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Unit of Measurement",
      accessor: "unitOfMeasurementName",
    },
    {
      header: "List Price",
      accessor: "listPrice",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Gross",
      accessor: "gross",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "VAT",
      accessor: "vat",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Net Before Trade Discount",
      accessor: "netBeforeTradeDiscount",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Subtotal",
      accessor: "subtotal",
      Cell: (children) => children && formatMoney(children),
    },
  ];
  const columns = [
    {
      header: "Actions",
      accessor: "transactionNumber",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },

    { header: "Order Number", accessor: "order.orderNumber" },
    {
      header: "Transaction Date",
      accessor: "transactionDate",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    { header: "Transaction Number", accessor: "transactionNumber" },
    {
      header: "Account Code",
      accessor: "storeAccountCode",
    },
    {
      header: "Store Name",
      accessor: "storeName",
    },
    {
      header: "Expected Delivery Date",
      accessor: "expectedDeliveryDate",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Total",
      accessor: "total",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Items",
      accessor: "salesInvoiceItems",
      Cell: (children, collapse, setCollapse, index) => {
        return (
          <div>
            {collapse.includes(index) ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCollapse(collapse.filter((item) => item !== index));
                }}
              >
                Items
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setCollapse([...collapse, index]);
                }}
              >
                Items
              </Button>
            )}
            <Portal container={document.querySelector(`#portal-${index}`)}>
              <Collapse
                in={collapse.includes(index)}
                timeout="auto"
                unmountOnExit
              >
                <NestedTable
                  columns={salesInvoiceItemsColumns}
                  data={children}
                />
              </Collapse>
            </Portal>
          </div>
        );
      },
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={[...columns, ...salesInvoiceItemsColumns]
          .filter(
            ({ header, accessor }) => header !== "Items" && accessor !== "id"
          )
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <ButtonGroup
          variant="contained"
          color="secondary"
          className="actionButtons"
        >
          <Button
            onClick={() => history.push(`${history.location.pathname}/upload`)}
          >
            Upload
          </Button>
          <Button onClick={handleDownload}>Download</Button>
        </ButtonGroup>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={(e) => {
              setCollapse([]);
              handleSubmitSearch()(e);
            }}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable
            data={salesInvoices}
            columns={columns}
            collapse={collapse}
            setCollapse={setCollapse}
          />
        </Paper>
        <Pagination
          query={SALES_INVOICES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default SalesInvoicesPage;
