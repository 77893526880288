import { gql, useQuery } from "@apollo/client";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router";
import PageListView from "../../../styles/pages/PageListView";
import { computeNetTotal } from "../../../utils/compute";
import formatMoney from "../../../utils/formatMoney";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";

const ORDER = gql`
  query Order($where: OrderWhereUniqueInput!) {
    order(where: $where) {
      orderNumber
      deliveryScheduleDate
      grossTotal
      productDiscount
      vat
      customerDiscount
      netTotal
      orderItems {
        id
        itemCode
        itemDescription
        quantity
        unitOfMeasurementName
        promoFreeQuantity
        subtotal
        salesInvoiceItem {
          id
          quantity
          gross
          vat
          netBeforeTradeDiscount
          unitOfMeasurementName
          promoFreeQuantity
          subtotal
        }
      }
      salesInvoice {
        transactionNumber
        expectedDeliveryDate
        salesInvoiceItems {
          id
          itemCode
          itemDescription
          quantity
          gross
          vat
          netBeforeTradeDiscount
          unitOfMeasurementName
          promoFreeQuantity
          subtotal
        }
      }
    }
  }
`;

const ViewOrderPage = () => {
  let { orderNumber } = useParams();

  const columns = [
    {
      header: "Item Code",
      accessor: "itemCode",
    },
    {
      header: "Item Description",
      accessor: "itemDescription",
    },
    {
      header: "Quantity",
      accessor: "quantity",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Unit",
      accessor: "unitOfMeasurementName",
    },
    {
      header: "Free Goods Qty",
      accessor: "promoFreeQuantity",
      Cell: (children) => (children ? children.toLocaleString() : 0),
    },
    {
      header: "Subtotal",
      accessor: "subtotal",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Approved Quantity",
      accessor: "salesInvoiceItem.quantity",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Approved Unit",
      accessor: "salesInvoiceItem.unitOfMeasurementName",
    },
    {
      header: "Approved Free Goods Qty",
      accessor: "salesInvoiceItem.promoFreeQuantity",
      Cell: (children) => (children ? children.toLocaleString() : 0),
    },
    {
      header: "Final Subtotal",
      accessor: "salesInvoiceItem.subtotal",
      Cell: (children) => children && formatMoney(children),
    },
  ];
  const { data: { order } = {}, loading } = useQuery(ORDER, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        orderNumber,
      },
    },
  });
  if (loading) return <Loading />;
  const orderItemsAndSalesInvoiceItemsWithoutOrderItemsArr = [
    ...order?.orderItems,
    ...(order?.salesInvoice?.salesInvoiceItems
      .filter(
        ({ itemCode, unitOfMeasurementName }) =>
          !order?.orderItems.some(
            (item) =>
              item.itemCode === itemCode &&
              item.unitOfMeasurementName === unitOfMeasurementName
          )
      )
      .map(({ itemCode, itemDescription, ...item }) => {
        return { itemCode, itemDescription, salesInvoiceItem: { ...item } };
      }) || []),
  ];

  const title = `Order# ${order.orderNumber}`;
  const finalGrossTotal =
    orderItemsAndSalesInvoiceItemsWithoutOrderItemsArr.reduce(
      (acc, { salesInvoiceItem }) => {
        if (!salesInvoiceItem) return acc;
        return acc + salesInvoiceItem.gross;
      },
      0
    );
  const finalProductDiscount =
    orderItemsAndSalesInvoiceItemsWithoutOrderItemsArr.reduce(
      (acc, { salesInvoiceItem }) => {
        if (!salesInvoiceItem) return acc;
        return (
          acc +
          salesInvoiceItem.gross +
          salesInvoiceItem.vat -
          salesInvoiceItem.netBeforeTradeDiscount
        );
      },
      0
    );
  const finalCustomerDiscount =
    orderItemsAndSalesInvoiceItemsWithoutOrderItemsArr.reduce(
      (acc, { salesInvoiceItem }) => {
        if (!salesInvoiceItem) return acc;
        return (
          acc +
          salesInvoiceItem.netBeforeTradeDiscount -
          salesInvoiceItem.subtotal
        );
      },
      0
    );
  const finalVat = orderItemsAndSalesInvoiceItemsWithoutOrderItemsArr.reduce(
    (acc, { salesInvoiceItem }) => {
      if (!salesInvoiceItem) return acc;
      return acc + salesInvoiceItem.vat;
    },
    0
  );
  return (
    <PageListView>
      <Meta title={`${title} | BPD Admin`} />
      <Typography variant="h4" color="primary">
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="secondary"
        sx={{ fontWeight: "bold" }}
        gutterBottom
      >
        Delivery Schedule:{" "}
        {order?.salesInvoice?.expectedDeliveryDate
          ? format(
              new Date(order?.salesInvoice?.expectedDeliveryDate),
              "dd MMM yyyy"
            )
          : format(new Date(order.deliveryScheduleDate), "dd MMM yyyy")}
      </Typography>
      <Paper className="dataTableContainer">
        <DataTable
          data={orderItemsAndSalesInvoiceItemsWithoutOrderItemsArr}
          columns={columns}
        />
      </Paper>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} md={5}>
          <Paper sx={{ ml: "auto", mt: 1, p: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold" }}
              color="primary"
              gutterBottom
            >
              Summary
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {" "}
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }} align="right">
                  Ordered
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }} align="right">
                  Approved
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }}>Gross Total</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {formatMoney(order.grossTotal)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {formatMoney(finalGrossTotal)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Product Discount
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {formatMoney(-order.productDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {formatMoney(-finalProductDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Customer Discount
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {formatMoney(-order.customerDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  {formatMoney(-finalCustomerDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }}>VAT</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">{formatMoney(order.vat)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">{formatMoney(finalVat)}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }}>Net Total:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }} align="right">
                  {formatMoney(
                    computeNetTotal(
                      order.grossTotal,
                      order.productDiscount,
                      order.customerDiscount,
                      order.vat
                    )
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: "bold" }} align="right">
                  {formatMoney(
                    computeNetTotal(
                      finalGrossTotal,
                      finalProductDiscount,
                      finalCustomerDiscount,
                      finalVat
                    )
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </PageListView>
  );
};

export default ViewOrderPage;
