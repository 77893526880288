import { gql, useMutation } from "@apollo/client";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Button } from "@mui/material";
import React from "react";
import { useAuthContext } from "../../contexts/authContext";
import { useSnackbarContext } from "../../contexts/snackbarContext";

const ADD_TO_CART = gql`
  mutation AddToCart($data: CartItemCreateInput!) {
    addToCart(data: $data) {
      id
    }
  }
`;
const AddToCartButton = ({ quantity, unitOfMeasurement }) => {
  const { handleSnackbarOpen } = useSnackbarContext();
  const { currentUser, currentUserRefetch } = useAuthContext();

  const [addToCart, { loading: mutationLoading }] = useMutation(ADD_TO_CART, {
    onError: (error) =>
      handleSnackbarOpen({
        message: "Add to cart error! " + error,
        severity: "error",
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: "Add to cart success!",
        severity: "success",
      });
      currentUserRefetch();
    },
  });

  const handleAddToCart = async () => {
    await addToCart({
      variables: {
        data: {
          quantity,
          unitOfMeasurement: {
            connect: {
              id: unitOfMeasurement?.id,
            },
          },
          customer: {
            connect: {
              id: currentUser?.customer?.id,
            },
          },
        },
      },
    });
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={handleAddToCart}
        color="secondary"
        startIcon={<AddShoppingCartIcon />}
        type="submit"
        disabled={mutationLoading}
        sx={{ height: "100%" }}
      >
        Add to Cart
      </Button>
    </>
  );
};

export default AddToCartButton;
