import { Container, Paper } from "@mui/material";
import React from "react";
import DrawerHeader from "../../styles/components/DrawerHeader";

const FormContainer = ({ children, maxWidth = "lg" }) => {
  return (
    <>
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "auto",
          p: {
            xs: 0,
            md: 3,
          },
        }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          {children}
        </Paper>
      </Container>
      <DrawerHeader />
    </>
  );
};

export default FormContainer;
