import { gql, useQuery } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  styled,
  Paper,
  Chip,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import queryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PageListView from "../../styles/pages/PageListView";
import Meta from "../common/Meta";
import Loading from "../common/Loading";
import FiltersDialog from "./FiltersDialog";
import Product from "./Product";
import { AD_PLACEMENTS } from "../admin/ad_placements/AdPlacementsPage";
import { Carousel } from "react-responsive-carousel";
import Search from "../common/Search";
import useQueryString from "../../hooks/useQueryString";

const PRODUCTS = gql`
  query Products(
    $skip: Int
    $take: Int
    $where: ProductWhereInput
    $orderBy: [ProductOrderByInput!]
  ) {
    storeProducts(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      company
      marketingDivision
      itemCode
      itemDescription
      taxSchedule
      listPrice
      businessUnit
      brand
      image
      discount
      unitOfMeasurements {
        id
        multiplierToBasicUnit
        name
        promo {
          id
          buyQuantity
          freeQuantity
        }
      }
    }
  }
`;

const PRODUCTS_COUNT = gql`
  query ProductsCount($where: ProductWhereInput) {
    storeProductsCount(where: $where)
  }
`;

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  position: "sticky",
  bottom: 0,
}));

const ProductsPage = () => {
  const client = useApolloClient();
  const title = `Products`;
  const history = useHistory();
  let location = useLocation();

  const parsed = queryString.parse(location.search);
  const companyInitialValue = parsed.company;
  const businessUnitInitialValue = parsed.businessUnit;
  const marketingDivisionInitialValue = parsed.marketingDivision;
  const brandInitialValue = parsed.brand;
  const sortByInitialValue = parsed.sortBy;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
  } = useQueryString("search");

  const [company, setCompany] = useState(companyInitialValue);
  const [businessUnit, setBusinessUnit] = useState(businessUnitInitialValue);
  const [marketingDivision, setMarketingDivision] = useState(
    marketingDivisionInitialValue
  );
  const [brand, setBrand] = useState(brandInitialValue);
  const [sortBy, setSortBy] = useState(sortByInitialValue);

  // const itemDescriptionWhere = searchTerm && {
  //   itemDescription: {
  //     contains: searchTerm,
  //     mode: "insensitive",
  //   },
  // };
  // const companyWhere = company && { company: { equals: company } };
  // const businessUnitWhere = businessUnit && {
  //   businessUnit: { equals: businessUnit },
  // };
  // const marketingDivisionWhere = marketingDivision && {
  //   marketingDivision: { equals: marketingDivision },
  // };
  // const brandWhere = brand && {
  //   brand: { equals: brand },
  // };

  const where = {
    ...((searchTerm && {
      itemDescription: {
        contains: searchTerm,
        mode: "insensitive",
      },
    }) ||
      {}),
    ...((company && { company: { equals: company } }) || {}),
    ...((businessUnit && {
      businessUnit: { equals: businessUnit },
    }) ||
      {}),
    ...((marketingDivision && {
      marketingDivision: { equals: marketingDivision },
    }) ||
      {}),
    ...((brand && {
      brand: { equals: brand },
    }) ||
      {}),
  };

  const orderBy =
    sortBy === "Marketing Div A-Z"
      ? {
          marketingDivision: "asc",
        }
      : {
          itemDescription: "asc",
        };
  const [page, setPage] = useState(1);
  const perPage = 30;
  const loadingRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: { adPlacements } = {}, loading: adPlacementsLoading } =
    useQuery(AD_PLACEMENTS, {
      variables: {
        where: {
          type: {
            equals: "IMAGE",
          },
          location: {
            equals: "PRODUCTS_PAGE_8x1",
          },
        },
      },
    });
  const {
    data: { storeProducts: products } = {},
    loading: productsLoading,
    fetchMore,
    refetch: refetchProducts,
  } = useQuery(PRODUCTS, {
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where: {
        ...where,
      },
      orderBy,
    },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: { storeProductsCount: productsCount } = {},
    refetch: refetchProductsCount,
  } = useQuery(PRODUCTS_COUNT, {
    variables: {
      where: {
        ...where,
      },
    },
  });

  const maxPage = Math.ceil(productsCount / perPage);

  const handleApplyFilter = () => {
    const companyQueryString = company && { company };
    const businessUnitQueryString = businessUnit && { businessUnit };
    const marketingDivisionQueryString = marketingDivision && {
      marketingDivision,
    };
    const itemDescriptionQueryString = searchTerm && {
      search: searchTerm,
    };
    const brandQueryString = brand && {
      brand,
    };
    const sortByQueryString = sortBy && {
      sortBy,
    };

    history.push({
      location: location.pathname,
      search: queryString.stringify({
        ...itemDescriptionQueryString,
        ...companyQueryString,
        ...businessUnitQueryString,
        ...marketingDivisionQueryString,
        ...brandQueryString,
        ...sortByQueryString,
      }),
    });
    window.scrollTo({ top: 0, transition: "smooth" });
    setPage(1);
    refetchProducts({
      skip: 0,
      take: perPage,
    });
    refetchProductsCount();
    handleDialogClose();
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDialogOpen(false);
  };
  const handleDialogOpen = (component = <></>) => {
    setDialogOpen(true);
  };

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prevState) => prevState + 1);
    }
  }, []);

  useEffect(() => {
    if (page > 1 && page <= maxPage) {
      fetchMore({
        variables: {
          skip: page * perPage - perPage,
          take: perPage,
        },
      });
    }
  }, [page, fetchMore, maxPage]);

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }
  }, [handleObserver, products]);

  useEffect(() => {
    return () => {
      client.resetStore();
    };
  }, [client]);

  if (!products || adPlacementsLoading) return <Loading />;
  return (
    <>
      <Meta title="Products | Bestline Pharma Distribution" />
      <PageListView>
        <Box
          sx={{
            py: "10px",
            position: "sticky",
            top: "65px",
            bgcolor: (theme) => theme.palette.background.default,
            zIndex: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" color="primary">
              {title}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                startIcon={<FilterAltIcon />}
                onClick={handleDialogOpen}
              >
                Filters
              </Button>
              <Search
                handleChangeSearch={handleChangeSearch}
                handleSubmitSearch={(e) => {
                  handleSubmitSearch()(e);
                  window.scrollTo({ top: 0, transition: "smooth" });
                  setPage(1);

                  refetchProducts({
                    skip: 0,
                    take: perPage,
                  });
                  refetchProductsCount();
                }}
                searchTerm={searchTerm}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ minHeight: "36px" }}>
            {companyInitialValue && (
              <Chip label={companyInitialValue} color="secondary" />
            )}
            {businessUnitInitialValue && (
              <Chip label={businessUnitInitialValue} color="secondary" />
            )}
            {marketingDivisionInitialValue && (
              <Chip label={marketingDivisionInitialValue} color="secondary" />
            )}
            {brandInitialValue && (
              <Chip label={brandInitialValue} color="secondary" />
            )}
            {sortByInitialValue && (
              <Chip label={sortByInitialValue} color="secondary" />
            )}
          </Stack>
        </Box>
        {products.length > 0 ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2, 1fr)",
              },
              gridGap: (theme) => theme.spacing(1),
            }}
          >
            {products.map((product) => (
              <Product key={product.itemCode} {...product} />
            ))}
          </Box>
        ) : (
          <Paper sx={{ py: 2 }}>
            <Typography variant="h6" align="center">
              No items here
            </Typography>
          </Paper>
        )}
        {productsLoading && <CircularProgress sx={{ my: 2, mx: "auto" }} />}
        <Box ref={loadingRef} sx={{ height: "50px" }} />
        <FiltersDialog
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          handleApplyFilter={handleApplyFilter}
          company={company}
          setCompany={setCompany}
          businessUnit={businessUnit}
          setBusinessUnit={setBusinessUnit}
          marketingDivision={marketingDivision}
          setMarketingDivision={setMarketingDivision}
          brand={brand}
          setBrand={setBrand}
          sortBy={sortBy}
          setSortBy={setSortBy}
          where={where}
        />
      </PageListView>
      <StyledCarousel
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
      >
        {adPlacements.map(({ media }, idx) => (
          <img
            key={idx}
            src={`${process.env.REACT_APP_BACKEND_URI}/${media}`}
            alt={`carousel-${idx}`}
          />
        ))}
      </StyledCarousel>
    </>
  );
};

export default ProductsPage;
