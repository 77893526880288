import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import { useDialogContext } from "../../../contexts/dialogContext";
import useQueryString from "../../../hooks/useQueryString";
import PageListView from "../../../styles/pages/PageListView";
import formatMoney from "../../../utils/formatMoney";
import AlertDialog from "../../common/AlertDialog";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const PRODUCTS = gql`
  query Products(
    $skip: Int
    $take: Int
    $where: ProductWhereInput
    $orderBy: [ProductOrderByInput!]
  ) {
    products(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      company
      marketingDivision
      itemCode
      itemDescription
      taxSchedule
      listPrice
      businessUnit
      brand
      image
      discount
      unitOfMeasurements {
        id
        multiplierToBasicUnit
        name
        promo {
          id
          buyQuantity
          freeQuantity
        }
      }
    }
  }
`;

const PRODUCTS_COUNT = gql`
  query ProductsCount($where: ProductWhereInput) {
    productsCount(where: $where)
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($where: ProductWhereUniqueInput!) {
    deleteProduct(where: $where) {
      itemCode
    }
  }
`;

const DELETE_ALL_PRODUCTS = gql`
  mutation DeleteAllProducts {
    deleteAllProducts {
      itemCode
    }
  }
`;
const ProductsPage = () => {
  const title = `Products`;
  const history = useHistory();
  const client = useApolloClient();
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleSnackbarOpen } = useSnackbarContext();
  const { handleDialogOpen, handleDialogClose } = useDialogContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = searchQueryString && {
    OR: [
      {
        company: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        marketingDivision: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        itemCode: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        itemDescription: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        brand: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
      {
        businessUnit: {
          contains: searchQueryString,
          mode: "insensitive",
        },
      },
    ],
  };
  const { data: { products } = {}, loading } = useQuery(PRODUCTS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const [deleteAllProducts] = useMutation(DELETE_ALL_PRODUCTS, {
    refetchQueries: [{ query: PRODUCTS_COUNT }],
    onError: (error) =>
      handleSnackbarOpen({
        message: `Delete all ${title.toLocaleLowerCase()} error! ` + error,
        severity: "error",
      }),
    onCompleted: () => {
      handleSnackbarOpen({
        message: `Delete all ${title.toLocaleLowerCase()} success!`,
        severity: "success",
      });
    },
    update(cache, { data: { deleteAllProducts } }) {
      cache.modify({
        fields: {
          products(existingProducts, { readField }) {
            return existingProducts.filter(
              (product) =>
                !deleteAllProducts.some(
                  ({ itemCode }) => itemCode === readField("itemCode", product)
                )
            );
          },
        },
      });
    },
  });
  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [{ query: PRODUCTS_COUNT }],
    onError: (error) =>
      handleSnackbarOpen({
        message: "Delete product error! " + error,
        severity: "error",
      }),
    update(cache, { data: { deleteProduct } }) {
      cache.modify({
        fields: {
          products(existingProducts, { readField }) {
            return existingProducts.filter(
              (product) =>
                deleteProduct.itemCode !== readField("itemCode", product)
            );
          },
        },
      });
    },
  });

  const handleDelete = async (itemCode) => {
    await deleteProduct({
      variables: { where: { itemCode } },
    });
  };

  const handleDeleteAll = async () => {
    await deleteAllProducts();
  };
  const handleDownload = async () => {
    const { data: { products } = {} } = await client.query({
      query: PRODUCTS,
      fetchPolicy: "network-only",
      variables: {
        skip: null,
        take: null,
        where,
      },
    });
    setExportData(products);
    csvLink.current.link.click();
  };
  const columns = [
    {
      header: "Actions",
      accessor: "itemCode",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Company", accessor: "company" },
    { header: "Marketing Division", accessor: "marketingDivision" },
    { header: "Item Code", accessor: "itemCode" },
    { header: "Item Description", accessor: "itemDescription" },
    { header: "Tax Schedule", accessor: "taxSchedule" },
    {
      header: "List Price",
      accessor: "listPrice",
      Cell: (children) => children && formatMoney(children),
    },
    { header: "Business Unit", accessor: "businessUnit" },
    { header: "Brand", accessor: "brand" },
    {
      header: "Image",
      accessor: "image",
      Cell: (children) =>
        children && (
          <Link target="_blank" rel="noopener noreferrer" href={children}>
            Image
          </Link>
        ),
    },
    {
      header: "Discount",
      accessor: "discount",
      Cell: (children) => children && (children * 100).toFixed(2) + "%",
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        enclosingCharacter={`'`}
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${title}-${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Stack direction="row">
          <Button
            onClick={() =>
              handleDialogOpen(
                <AlertDialog
                  handleClose={handleDialogClose}
                  callback={handleDeleteAll}
                  dialogContentText="Are you sure you want to delete all items?"
                />
              )
            }
            variant="contained"
            color="secondary"
          >
            Delete All
          </Button>
          <ButtonGroup
            variant="contained"
            color="secondary"
            className="actionButtons"
          >
            <Button
              onClick={() =>
                history.push(`${history.location.pathname}/upload`)
              }
            >
              Upload
            </Button>
            <Button onClick={handleDownload}>Download</Button>
          </ButtonGroup>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={products} columns={columns} />
        </Paper>
        <Pagination
          query={PRODUCTS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default ProductsPage;
