import React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle } from "@mui/material";

const AlertDialog = ({
  handleClose = () => {},
  callback = () => {},
  dialogTitle = "",
  dialogContentText = "",
}) => {
  return (
    <>
      {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button
          onClick={() => {
            callback();
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </>
  );
};

export default AlertDialog;
