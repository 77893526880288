import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";

const MiniCart = () => {
  const history = useHistory();
  const { currentUser } = useAuthContext();

  const cartQuantity =
    useMemo(() => {
      if (currentUser?.customer?.cartItems.length > 0)
        return currentUser?.customer?.cartItems.length;
    }, [currentUser]) || 0;

  return (
    <>
      <IconButton
        aria-label="items in cart"
        color="inherit"
        onClick={() => history.push("/cart")}
        size="large"
      >
        <Badge badgeContent={cartQuantity} showZero color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    </>
  );
};

export default MiniCart;
