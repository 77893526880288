const formatMoney = function (amount) {
  const options = {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 2,
  };

  // if (amount % 1 === 0) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat("en-PH", options);
  return formatter.format(amount);
};

export default formatMoney;
