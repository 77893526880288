import { gql, useQuery } from "@apollo/client";
import { Box, styled } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider } from "../../contexts/authContext";
import { DialogProvider } from "../../contexts/dialogContext";
import useDialog from "../../hooks/useDialog";
import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarProvider } from "../../contexts/snackbarContext";
import DrawerHeader from "../../styles/components/DrawerHeader";
import AuthGuard from "../common/AuthGuard";
import Meta from "../common/Meta";
import AppDialog from "./AppDialog";
import AppDrawer from "./AppDrawer";
import AppSnackbar from "./AppSnackbar";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Routes from "./Routes";
import ErrorBoundary from "../common/ErrorBoundary";

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      staff {
        id
      }
      customer {
        id
        name
        status
        stores {
          name
          accountCode
        }
        cartItems {
          id
          quantity
          unitOfMeasurement {
            id
            name
            promo {
              id
              buyQuantity
              freeQuantity
            }
            multiplierToBasicUnit
            product {
              itemCode
              company
              marketingDivision
              itemDescription
              taxSchedule
              listPrice
              businessUnit
              brand
              image
              discount
              unitOfMeasurements {
                id
                multiplierToBasicUnit
                name
                promo {
                  id
                  buyQuantity
                  freeQuantity
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StyledPage = styled("div")(() => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
}));

const Main = styled("main")(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

const Page = () => {
  const location = useLocation();
  const isAdminPath = location.pathname.includes("admin");
  const {
    snackbarMessage,
    snackbarSeverity,
    snackbarOpen,
    handleSnackbarOpen,
    handleSnackbarClose,
  } = useSnackbar();
  const { dialogOpen, dialogComponent, handleDialogOpen, handleDialogClose } =
    useDialog();
  const {
    data: { currentUser = {} } = {},
    loading: currentUserLoading,
    refetch: currentUserRefetch,
    networkStatus,
  } = useQuery(CURRENT_USER, {
    fetchPolicy: "network-only",
    // notifyOnNetworkStatusChange works in combination with AuthGuard and awaitRefetchQueryies: true
    notifyOnNetworkStatusChange: true,
  });

  if (currentUserLoading || (currentUserLoading && networkStatus !== 4))
    return null;

  return (
    <>
      {isAdminPath ? (
        <Meta title="BPD Admin" />
      ) : (
        <Meta title="Bestline Pharma Distribution" />
      )}
      <AuthProvider
        value={{
          currentUser,
          currentUserLoading,
          currentUserRefetch,
          isStaff: Boolean(currentUser?.staff?.id),
          isCustomer: Boolean(
            currentUser?.customer?.id &&
              currentUser?.customer?.status === "APPROVED"
          ),
        }}
      >
        <DialogProvider
          value={{
            dialogOpen,
            dialogComponent,
            handleDialogOpen,
            handleDialogClose,
          }}
        >
          <SnackbarProvider
            value={{
              handleSnackbarOpen,
              snackbarOpen,
              handleSnackbarClose,
              snackbarSeverity,
              snackbarMessage,
            }}
          >
            <StyledPage>
              {isAdminPath ? <AppDrawer /> : <NavBar />}
              <DrawerHeader />
              <Main>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    padding: 1,
                    mx: "auto",
                    width: "100%",
                    maxWidth: (theme) => ({
                      xs: theme.breakpoints.values.lg,
                      xl: theme.breakpoints.values.xl,
                    }),
                  }}
                >
                  <AuthGuard>
                    <ErrorBoundary>
                      <Routes />
                    </ErrorBoundary>
                  </AuthGuard>
                </Box>
                <AppSnackbar />
                <AppDialog />
              </Main>
              <Footer />
            </StyledPage>
          </SnackbarProvider>
        </DialogProvider>
      </AuthProvider>
    </>
  );
};

export default Page;
