import { debounce } from "@mui/material";
import { useReducer, useState } from "react";
import { useApolloClient } from "@apollo/client";

const useAutocomplete = (
  { modifiedInputReducer } = {},
  inputInitialState = {}
) => {
  const client = useApolloClient();
  const [open, setOpen] = useState([false]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState([false]);
  const defaultInputReducer = debounce(
    async (state, { query, variables, idx = 0 }) => {
      let loadingArray = new Array(idx + 1).fill(false);
      loadingArray[idx] = true;
      setLoading(loadingArray);
      const { data = {} } = await client.query({
        query,
        variables,
        fetchPolicy: "network-only",
      });
      const options = data[Object.keys(data)[0]];
      setOptions(options);
      setLoading([false]);
    },
    350
  );

  const inputReducer = modifiedInputReducer
    ? modifiedInputReducer
    : defaultInputReducer;

  const [inputState, dispatchInput] = useReducer(
    inputReducer,
    inputInitialState
  );

  return {
    open,
    setOpen,
    options,
    setOptions,
    loading,
    inputState,
    dispatchInput,
    setLoading,
  };
};

export { useAutocomplete };
