import { useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { yellow } from "@mui/material/colors";
import StarIcon from "@mui/icons-material/Star";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box } from "@mui/system";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router";
import { useAuthContext } from "../../contexts/authContext";
import truck from "../../images/home/truck.jpg";
import unilab from "../../images/home/unilab.png";
import warehouse from "../../images/home/warehouse.JPG";
import { AD_PLACEMENTS } from "../admin/ad_placements/AdPlacementsPage";
import Loading from "./Loading";

const HomePage = () => {
  const history = useHistory();
  const { isCustomer } = useAuthContext();
  const { data: { adPlacements } = {}, loading } = useQuery(AD_PLACEMENTS, {
    variables: {
      where: {
        type: {
          equals: "IMAGE",
        },
        location: {
          equals: "HOMEPAGE_1x1",
        },
      },
    },
  });
  const speelList = [
    "Consistent YOY sales growth since inception in 2005",
    "Saturation-focused YOY growth in number of doors served",
    "Recognized for warehouse practices in pharmaceutical distribution",
    "Dependable on-time deliveries of 1-2 days",
  ];
  const awardsList = [
    "Best in Backroom Management (2009)",
    "Best in Reach (2009)",
    "Best in Retail Performance Standard (2013)",
    "Most Valuable Distributor (2013)",
    "Best in Customer Service Level (2014)",
    "Most Valuable Distributor, runner-up (2017)",
    "Special Citation for Engagia Execution (2020)",
  ];

  if (loading) return <Loading />;

  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          <Paper
            square
            variant="outlined"
            sx={{
              border: 0,
              display: "flex",
              flexDirection: "column",
              p: 5,
              height: "100%",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h2"
              color="primary"
              component="h1"
              align="center"
              sx={{ fontWeight: "bold", mb: 3 }}
            >
              Serving drugstores
              <br /> and retailers, <br />
              <Box component="span" sx={{ textDecoration: "underline" }}>
                now online
              </Box>
              .
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 3 }}>
              BPD is the official, exclusive distributor of Unilab products in
              Laguna, Cavite, and
              <br /> Upper Quezon since 2005. Unilab Group is the Philippines'
              largest manufacturer <br />
              of world-class quality medicine.
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-around"
              spacing={1}
            >
              <Button
                variant="contained"
                size="large"
                sx={{ borderRadius: "32px" }}
                onClick={() => {
                  history.push("/products");
                }}
              >
                See Our Products
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ flexDirection: "column", borderRadius: "32px" }}
                onClick={() => {
                  isCustomer
                    ? history.push("/products")
                    : history.push("/signin");
                }}
              >
                <Typography variant="button">Order Online</Typography>
                <Typography
                  variant="caption"
                  sx={{ textTransform: "lowercase" }}
                >
                  (Sign-in Required)
                </Typography>
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sx={{ "& .thumbs": { padding: 0 } }}>
          <Carousel
            showStatus={false}
            showArrows={false}
            infiniteLoop={true}
            autoPlay={true}
          >
            {adPlacements.map(({ media }, idx) => (
              <img
                key={idx}
                src={`${process.env.REACT_APP_BACKEND_URI}/${media}`}
                alt={`carousel-${idx}`}
              />
            ))}
          </Carousel>
        </Grid>
      </Grid>
      <Box
        component="img"
        src={unilab}
        alt="unilab"
        sx={{ width: "100%", my: 2 }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Box
            component="img"
            src={truck}
            alt="truck"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            square
            variant="outlined"
            sx={{
              border: 0,
              display: "flex",
              flexDirection: "column",
              p: 5,
              height: "100%",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold" }}
              align="center"
              color="primary"
            >
              Championing <br /> Growth, Reach, <br /> and Efficiency
            </Typography>
            <List dense={true}>
              {speelList.map((item, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <TrendingUpIcon
                      sx={{
                        color: yellow[700],
                        mr: 1,
                        stroke: (theme) => theme.palette.common.black,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ my: 2 }}
        direction={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item xs={12} md={4}>
          <Paper
            square
            variant="outlined"
            sx={{
              border: 0,
              display: "flex",
              flexDirection: "column",
              p: 5,
              height: "100%",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold" }}
              align="center"
              color="primary"
            >
              Recognitions and Awards
            </Typography>
            <List dense={true}>
              {awardsList.map((item, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <StarIcon
                      sx={{
                        color: yellow[700],
                        mr: 1,
                        stroke: (theme) => theme.palette.common.black,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            component="img"
            src={warehouse}
            alt="warehouse"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
