import { gql, useMutation, useQuery } from "@apollo/client";
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useDialogContext } from "../../../contexts/dialogContext";
import PageListView from "../../../styles/pages/PageListView";
import DataTable from "../../common/DataTable";

import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import Meta from "../../common/Meta";
import AlertDialog from "../../common/AlertDialog";

const CUSTOMERS = gql`
  query Customers($skip: Int, $take: Int) {
    customers(skip: $skip, take: $take) {
      id
      name
      address
      contactNumber
      status
      user {
        id
        email
      }
    }
  }
`;

const CUSTOMERS_COUNT = gql`
  query CustomersCount {
    customersCount
  }
`;

const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($where: CustomerWhereUniqueInput!) {
    deleteCustomer(where: $where) {
      id
    }
  }
`;
const CustomersPage = () => {
  const title = `Users`;
  const history = useHistory();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const { handleDialogOpen, handleDialogClose } = useDialogContext();

  const { data: { customers } = {}, loading } = useQuery(CUSTOMERS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
    },
  });

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    refetchQueries: [{ query: CUSTOMERS_COUNT }],
    update(cache, { data: { deleteCustomer } }) {
      cache.modify({
        fields: {
          customers(existingCustomers, { readField }) {
            return existingCustomers.filter(
              (customer) => deleteCustomer.id !== readField("id", customer)
            );
          },
        },
      });
    },
  });

  const handleUpdate = (id) => {
    history.push(`${history.location.pathname}/${id}`);
  };
  const handleDelete = async (id) => {
    await deleteCustomer({
      variables: { where: { id } },
    });
  };
  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="Update">
              <IconButton
                aria-label="update"
                color="secondary"
                onClick={() => handleUpdate(children)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  handleDialogOpen(
                    <AlertDialog
                      handleClose={handleDialogClose}
                      callback={() => handleDelete(children)}
                      dialogContentText="Are you sure you want to delete this item?"
                    />
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },
    { header: "Email", accessor: "user.email" },
    { header: "Name", accessor: "name" },
    { header: "Address", accessor: "address" },
    { header: "Contact No.", accessor: "contactNumber" },
    { header: "Status", accessor: "status" },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <PageListView>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Paper className="dataTableContainer">
          <DataTable data={customers} columns={columns} />
        </Paper>
        <Pagination
          query={CUSTOMERS_COUNT}
          perPage={perPage}
          page={Number(page)}
        />
      </PageListView>
    </>
  );
};

export default CustomersPage;
