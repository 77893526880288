import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MedicationIcon from "@mui/icons-material/Medication";
import PersonIcon from "@mui/icons-material/Person";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptIcon from "@mui/icons-material/Receipt";
import StoreIcon from "@mui/icons-material/Store";
import StraightenIcon from "@mui/icons-material/Straighten";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import SignoutButton from "../authentication/SignoutButton";

const nav = [
  { name: "Staffs", icon: <PersonIcon /> },
  { name: "Users", icon: <GroupsIcon /> },
  { name: "Delivery Areas", icon: <BusinessIcon /> },
  { name: "Delivery Schedules", icon: <LocalShippingIcon /> },
  { name: "Stores", icon: <StoreIcon /> },
  { name: "Products", icon: <MedicationIcon /> },
  { name: "Unit of Measurements", icon: <StraightenIcon /> },
  { name: "Promos", icon: <LocalOfferIcon /> },
  { name: "Ad Placements", icon: <LiveTvIcon /> },
  { name: "Orders", icon: <PointOfSaleIcon /> },
  { name: "Sales Invoices", icon: <ReceiptIcon /> },
];

const Nav = ({ handleDrawerClose }) => {
  const history = useHistory();
  const { currentUser } = useAuthContext();
  const stringToUrl = (string) => {
    return string.toLowerCase().replace(/\s+/g, "-");
  };
  return (
    <Box
      sx={{ width: 280, mx: 1 }}
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
    >
      <List component="nav">
        {nav.map((item, idx) => (
          <div key={idx}>
            <ListItemButton
              key={idx}
              onClick={() => history.push(`/admin/${stringToUrl(item.name)}`)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </ListItemButton>
          </div>
        ))}
        {currentUser && (
          <SignoutButton
            variant="outlined"
            callback={() => {
              // handleDrawerClose();
              history.push("/admin/signin");
            }}
          />
        )}
      </List>
    </Box>
  );
};

export default Nav;
