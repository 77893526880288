import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import OrderSummary from "../cart/OrderSummary";
import Meta from "../common/Meta";

const OrderSummaryPage = () => {
  const title = `Order Summary`;
  const location = useLocation();
  const { currentUser } = useAuthContext();
  const cartItems = currentUser?.customer?.cartItems
    .map((cartItem) => {
      return {
        ...cartItem,
      };
    })
    .sort((a, b) => (a.id > b.id && 1) || -1);

  return (
    <Box sx={{ my: 1 }}>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={4}>
          <OrderSummary
            currentUser={currentUser}
            cartItems={cartItems}
            store={location?.state?.store}
            deliverySchedule={location?.state?.deliverySchedule}
            isOrderSummary={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderSummaryPage;
