import { Snackbar } from "@mui/material";
import React from "react";
import MuiAlert from "@mui/material/Alert";
import { useSnackbarContext } from "../../contexts/snackbarContext";

const AppSnackbar = () => {
  const {
    snackbarOpen,
    handleSnackbarClose,
    snackbarSeverity,
    snackbarMessage,
  } = useSnackbarContext();
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
      >
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
  );
};

export default AppSnackbar;
