import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import { useInputChange } from "../../../hooks/useInputChange";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import StaffForm from "./StaffForm";

const UPDATE_STAFF = gql`
  mutation UpdateStaff(
    $data: StaffUpdateInput!
    $where: StaffWhereUniqueInput!
  ) {
    updateStaff(data: $data, where: $where) {
      id
    }
  }
`;

const STAFF = gql`
  query Staff($where: StaffWhereUniqueInput!) {
    staff(where: $where) {
      id
      role
      user {
        id
        email
      }
    }
  }
`;
const UpdateStaffPage = () => {
  const title = `Update Staff`;
  let { id } = useParams();
  id = parseInt(id);
  const history = useHistory();
  const { handleSnackbarOpen } = useSnackbarContext();
  const { state, handleChange, setState } = useInputChange();

  const { data: { staff } = {} } = useQuery(STAFF, {
    fetchPolicy: "network-only",
    variables: {
      where: { id },
    },
  });
  const [updateStaff, { loading: mutationLoading }] = useMutation(
    UPDATE_STAFF,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.goBack();
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { role } = state;
    await updateStaff({
      variables: {
        data: {
          role: {
            set: role,
          },
        },
        where: {
          id,
        },
      },
    });
  };
  useEffect(() => {
    if (staff) {
      const { role, user: { email } = {} } = staff;
      setState({ role, email });
    }
  }, [staff, setState]);

  if (!staff) return <Loading />;
  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <StaffForm
        title={title}
        state={state}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        mutationLoading={mutationLoading}
      />
    </>
  );
};

export default UpdateStaffPage;
