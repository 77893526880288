import { gql, useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { parse } from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbarContext } from "../../../contexts/snackbarContext";
import CSVReaderComponent from "../../common/CSVReaderComponent";
import FormContainer from "../../common/FormContainer";
import Meta from "../../common/Meta";

const UPLOAD_DELIVERY_SCHEDULES = gql`
  mutation UploadDeliverySchedules($data: [DeliveryScheduleCreateInput]!) {
    uploadDeliverySchedules(data: $data)
  }
`;

const UploadDeliverySchedulesPage = () => {
  const title = `Upload Delivery Schedules`;
  const history = useHistory();
  const [parsedData, setParsedData] = useState([]);
  const { handleSnackbarOpen } = useSnackbarContext();
  const [uploadDeliverySchedules, { loading: mutationLoading }] = useMutation(
    UPLOAD_DELIVERY_SCHEDULES,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
        history.push("/admin/delivery-schedules");
      },
    }
  );
  const handleOnDrop = (data) => {
    setParsedData(
      data.filter(({ errors }) => errors.length === 0).map(({ data }) => data)
    );
  };
  const handleOnError = (err, file, inputElem, reason) => {
    handleSnackbarOpen({
      message: "CSV parse error! " + err,
      severity: "error",
    });
  };
  const handleOnRemoveFile = () => {
    setParsedData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await uploadDeliverySchedules({
      variables: {
        data: parsedData.map(({ date, deliveryArea }) => {
          return {
            date: parse(date, "dd/MM/yyyy", new Date()),
            deliveryArea: {
              connectOrCreate: {
                where: {
                  name: deliveryArea,
                },
                create: {
                  name: deliveryArea,
                },
              },
            },
          };
        }),
      },
    });
  };

  return (
    <>
      <Meta title={`${title} | BPD Admin`} />
      <FormContainer maxWidth="xs">
        <Typography
          component="h1"
          variant="h4"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <CSVReaderComponent
            handleOnDrop={handleOnDrop}
            handleOnError={handleOnError}
            handleOnRemoveFile={handleOnRemoveFile}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={parsedData.length === 0 || mutationLoading}
          >
            Submit
          </Button>
        </Box>
      </FormContainer>
    </>
  );
};

export default UploadDeliverySchedulesPage;
