import { gql, useMutation } from "@apollo/client";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import { useInputChange } from "../../hooks/useInputChange";
import FormContainer from "../common/FormContainer";
import Meta from "../common/Meta";

const REQUEST_RESET = gql`
  mutation RequestReset($email: String!) {
    requestReset(email: $email)
  }
`;

const RequestPasswordResetPage = () => {
  const title = `Request Password Reset`;

  const { state, handleChange } = useInputChange();
  const { handleSnackbarOpen } = useSnackbarContext();
  const [requestReset, { loading: mutationLoading }] = useMutation(
    REQUEST_RESET,
    {
      onError: (error) =>
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " error! " +
            error,
          severity: "error",
        }),
      onCompleted: () => {
        handleSnackbarOpen({
          message:
            title.charAt(0).toUpperCase() +
            title.slice(1).toLowerCase() +
            " success!",
          severity: "success",
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    requestReset({ variables: { ...state } });
  };

  return (
    <>
      <Meta title={`${title} | Bestline Pharma Distribution`} />
      <FormContainer maxWidth="xs">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                required
                variant="filled"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange()}
                type="email"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 1 }}
            disabled={mutationLoading}
          >
            Request Reset
          </Button>
        </Box>
      </FormContainer>
    </>
  );
};

export default RequestPasswordResetPage;
