import { Paper, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router";

const OrderConfirmationPage = () => {
  let { orderNumber } = useParams();

  return (
    <Paper sx={{ p: 2, m: "auto", maxWidth: "320px" }}>
      <Typography
        variant="h5"
        align="center"
        sx={{ fontWeight: "bold" }}
        gutterBottom
      >
        Order # {orderNumber}
      </Typography>
      <Typography gutterBottom align="center">
        Your order has been placed. We will confirm your order shortly.
      </Typography>
      <Typography
        variant="subtitle2"
        color="error"
        sx={{ fontWeight: "bold" }}
        align="center"
      >
        Disclaimer:
      </Typography>
      <Typography variant="subtitle2" color="error" align="center">
        Inventory and delivery schedule are not guaranteed. Price is subject to
        change and order is subject to approval.
      </Typography>
    </Paper>
  );
};

export default OrderConfirmationPage;
