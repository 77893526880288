import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import logo from "../../images/logos/logo112x100.png";
import DrawerHeader from "../../styles/components/DrawerHeader";
import Logo from "../../styles/components/Logo";
import Nav from "./Nav";

const AppDrawer = () => {
  const { currentUser } = useAuthContext();

  const [open, setOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {currentUser?.staff?.id && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Logo src={logo} alt="logo" />
          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              "& :not(:last-child)": { mr: 1 },
            }}
          >
            {currentUser?.staff?.id && (
              <>
                <AccountCircleIcon />
                <Typography variant="body1">{currentUser.email}</Typography>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <DrawerHeader>
          <Typography variant="h6" component="h1" color="primary">
            Bestline Pharma Distribution
          </Typography>
        </DrawerHeader>
        <Divider />
        <Nav handleDrawerClose={toggleDrawer(false)} />
      </Drawer>
    </>
  );
};

export default AppDrawer;
