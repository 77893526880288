import { useState } from "react";

const usePopper = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const handleClick = (newPlacement) => (e) => {
    setAnchorEl(e.currentTarget);
    setOpen((prevState) => !prevState);
    setPlacement(newPlacement);
  };

  return { open, setOpen, anchorEl, placement, handleClick };
};

export default usePopper;
