import { Paper } from "@mui/material";
import Fade from "@mui/material/Fade";
import MuiPopper from "@mui/material/Popper";
import React from "react";

const Popper = ({ open, anchorEl, placement, children }) => {
  return (
    <MuiPopper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
      style={{ zIndex: 3 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ p: 2 }}>{children}</Paper>
        </Fade>
      )}
    </MuiPopper>
  );
};

export default Popper;
